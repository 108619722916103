import React from "react";

import LandSearch from "../real-estate-components/land-components/LandSearch";
import HouseSearch from "../real-estate-components/house-components/HouseSearch";
import ApartmentSearch from "../real-estate-components/apartment-components/ApartmentSearch";

const RealEstatesSearch = () => {

    return (
        <div className="ltn__car-dealer-form-area mt--650 pb-115--- position-relativee">
            <div className="container-fluid container-lg">
                <div className="row bg-whitee position-relative">
                    <div className="col-lg-12 align-self-center">
                        <div className="slide-item-car-dealer-form">
                            <div className="ltn__car-dealer-form-tab">
                                <div className="ltn__tab-menu  text-uppercase">
                                    <div className="nav">
                                        <a
                                            className="active show"
                                            data-bs-toggle="tab"
                                            href="#ltn__form_tab_1_1"
                                        >
                                            <i className="fas fa-sign-hanging" />
                                            Terrain
                                        </a>
                                        <a
                                            data-bs-toggle="tab"
                                            href="#ltn__form_tab_1_2"
                                        >
                                            <i className="fas fa-home" />
                                            Maison
                                        </a>
                                        <a
                                            data-bs-toggle="tab"
                                            href="#ltn__form_tab_1_3"
                                        >
                                            <i className="fas fa-building" />
                                            Appartement
                                        </a>
                                    </div>
                                </div>
                                <div className="tab-content bg-transparentt">
                                    <div
                                        className="tab-pane fade active show"
                                        id="ltn__form_tab_1_1"
                                    >
                                        <LandSearch />
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="ltn__form_tab_1_2"
                                    >
                                        <HouseSearch />
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="ltn__form_tab_1_3"
                                    >
                                        <ApartmentSearch />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RealEstatesSearch;

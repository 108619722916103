import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/SocialsNetwork";
import RealEstatesSearch from "../section-components/RealEstatesSearch";
// import { useDataContext } from "../../data/providers/DataProvider";

/**
 * 
 * @returns 
 */
const SearchPanel = () => {

    return (
        <div
            id="searchPanelRight"
            className="search-panel offcanvas offcanvas-end"
            tabindex="-1"
            aria-labelledby="searchPanelRightLabel"
        >
            <div className="offcanvas-header">
                <h5
                    className="offcanvas-title"
                    id="searchPanelRightLabel"
                >
                    <span>Recherche</span>
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body px-0 px-sm-3">
                <RealEstatesSearch />
            </div>
        </div>
    );
};

export default SearchPanel;

import React from "react";

import Slider from "react-slick";

/**
 * 
 * @returns 
 */
const Testimonials = () => {

    const publicUrl = process.env.PUBLIC_URL + "/";

    const testimonials = [
        {
            name: 'Alioune Diop',
            img: `${publicUrl}assets/img/testimonial/1.jpg`,
            text:"Après plusieurs recherches pour trouver le terrain idéal pour notre future maison, nous avons finalement trouvé notre bonheur. Ce terrain était parfait, avec une belle vue et suffisamment d’espace pour y construire une maison spacieuse et un jardin. L'achat s'est bien déroulé grâce aux conseils de l'agent immobilier, qui a été très transparent concernant les démarches administratives et la viabilité du terrain. Aujourd'hui, nous avons démarré la construction, et nous sommes ravis de voir notre projet se concrétiser dans ce cadre idyllique.",
        },
        {
            name: 'Seynabou Diouf',
            img: `${publicUrl}assets/img/testimonial/2.jpg`,
            text:"J'ai acheté un terrain nu à proximité d'une grande ville dans le but d'en faire un investissement à long terme. Le terrain est situé dans une zone en plein développement, et je suis convaincue qu'il prendra de la valeur dans les prochaines années. L'achat s'est fait en toute simplicité, et j'ai eu toutes les informations nécessaires sur les éventuelles démarches administratives à suivre pour le lotissement ou le développement futur du terrain. Même si je n’ai pas encore de projets immédiats pour ce terrain, je suis confiante qu'il me rapportera une belle plus-value dans quelques années.",
        },
        {
            name: 'Francis Baye',
            img: `${publicUrl}assets/img/testimonial/3.jpg`,
            text:"Après plusieurs mois de recherches, j'ai enfin trouvé l'appartement de mes rêves grâce à l'agence immobilière. Je cherchais un bien qui soit proche de mon travail tout en étant dans un quartier calme et agréable. L'agent immobilier a parfaitement compris mes besoins et m'a proposé plusieurs options avant de trouver celle qui correspondait exactement à mes attentes. L'achat s'est déroulé rapidement et sans accroc, et je suis vraiment satisfaite de mon investissement. C'est un lieu que j'adore, et je me sens vraiment chez moi !",
        },
        {
            name: 'Abdoulaye Sow',
            img: `${publicUrl}assets/img/testimonial/4.jpg`,
            text:"J'ai acheté un studio il y a trois mois dans un quartier en développement avec l'intention de le louer. L'agent m'a bien conseillé sur le potentiel locatif de l'emplacement et sur les rénovations nécessaires pour maximiser le rendement. Le processus d'achat a été fluide, et j'ai pu trouver un locataire rapidement après avoir fait les rénovations. Le bien génère maintenant un bon revenu passif et a pris de la valeur depuis l'achat. Je suis vraiment satisfait de cet investissement.",
        },
    ]
    return (
        <div
            className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
            data-bs-bg={`${publicUrl}assets/img/bg/20.jpg`}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Témoignages</h6>
                            <h1 className="section-title">Clients Feedback</h1>
                        </div>
                    </div>
                </div>
                <Slider
                    arrows={true}
                    centerMode={false}
                    // centerPadding={'80px'}
                    dots={true}
                    infinite={true}
                    speed={300}
                    slidesToShow={2}
                    slidesToScroll={1}
                    prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                    nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                    responsive={[
                        {
                            breakpoint: 1400,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                arrows: false,
                                dots: true,
                                centerMode: false,
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: false,
                                dots: true,
                                centerMode: false,
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                arrows: false,
                                dots: true,
                                centerMode: false,
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]}
                    className="row ltn__testimonial-slider-5-active slick-arrow-1"
                >
                {
                    testimonials.map((testimonial, index) => {
                        return (
                            <div
                                key={index}
                                className="col-lg-6"
                            >
                                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                                    <div className="ltn__testimoni-info">
                                        <p className="fs-6">
                                            <i className="flaticon-left-quote-1" />{" "}
                                            {testimonial.text}
                                        </p>
                                        <div className="ltn__testimoni-info-inner">
                                            <div className="ltn__testimoni-img">
                                                <img
                                                    src={`${testimonial.img}`}
                                                    alt={testimonial.name}
                                                />
                                            </div>
                                            <div className="ltn__testimoni-name-designation">
                                                <h5>{testimonial.name}</h5>
                                                {/* <label>Selling Agents</label> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        );
                    })
                }
                </Slider>
            </div>
        </div>
    );
};

export default Testimonials;

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from './pages/Home';
import AlfaImmo from './pages/Alfaimmo';
import Contact from './pages/Contact';
import Houses from './pages/Houses';
import ErrorPage from './pages/404';
import Apartments from './pages/Apartments';
import House from './pages/House';
import Apartment from './pages/Apartment';
import RealEstatesLocations from './pages/RealEstatesLocations';
import MyAzureMap from './pages/MyAzureMap';
import UndividedPlots from './pages/lands/undivided-plots/UndividedPlots';
import UndividedPlot from './pages/lands/undivided-plots/UndividedPlot';
import Subdivisions from './pages/lands/subdivisions/Subdivisions';
import Subdivision from './pages/lands/subdivisions/Subdivision';
import Plot from './pages/lands/subdivisions/Plot';

// import './App.css';

function App() {

    return(
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Home />} />

                <Route path="/azure-map" element={<MyAzureMap />} />
                <Route path="/accueil" element={<Home />} />
                <Route path="/alfaimmo" element={<AlfaImmo />} />
                <Route path="/contact" element={<Contact />} />

                <Route path="/appartements" element={<Apartments />} />
                <Route path="/maisons" element={<Houses />} />
                <Route path="/terrains/lotissements" element={<Subdivisions />} />
                <Route path="/terrains/parcelles-non-loties" element={<UndividedPlots />} />

                <Route path="/appartements/:id" element={<Apartment />} />
                <Route path="/maisons/:id" element={<House />} />
                <Route path="/terrains/lotissements/:id" element={<Subdivision />} />
                <Route path="/terrains/parcelles-non-loties/:id" element={<UndividedPlot />} />
                <Route path="/terrains/lotissements/parcelles/:id" element={<Plot />} />

                <Route path="/localisation-des-biens-immobiliers" element={<RealEstatesLocations />} />

                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

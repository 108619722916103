import React from 'react';

const HomeVideo = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
        <div className="ltn__video-popup-area ltn__video-popup-margin---">
            <div className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-40 bg-image bg-fixed ltn__animation-pulse1" data-bs-bg={publicUrl+"assets/img/bg/img-2.jpg"}>
                <a
                    className="ltn__video-icon-2 ltn__video-icon-2-border---"
                    // href="#homeVideo"
                    href="https://www.youtube.com/embed/XMYQXiNPTvo?autoplay=1&showinfo=0"
                    data-rel="lightcase:myCollection"
                    // onClick={() => {
                    //     videoRef.current.className = 'w-100 d-block'
                    // }}
                >
                    <i className="fa fa-play" />
                </a>
                {/* <div id="homeVideo">
                    <video className="w-100" width="600" height="380" controls>
                        <source src={publicUrl + "assets/media/DJI_0438.mov"} type="video/mp4"/>
                        <source src="movie.ogg" type="video/ogg"/>
                        Votre navigateur ne prend pas en charge la balise vidéo.
                    </video>
                </div> */}
            </div>
        </div>
    );
};

export default HomeVideo;

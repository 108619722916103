import React from 'react';
import Navbar from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import AboutV4 from '../components/section-components/about-v5';
import Features from '../components/section-components/features-v1';
import Team from '../components/section-components/team-v1';
import Testimonial from '../components/section-components/Testimonials';
import BlogSlider from '../components/blog-components/blog-slider-v1';
import Footer from '../components/global-components/Footer';
import Newsletter from '../components/global-components/Newsletter';
import { Link } from 'react-router-dom';
import Services from '../components/section-components/Services';


const AlfaImmo = () => {

    const teams = [
        {
            img: 'assets/img/illustrations/man.png',
            fullname: 'Alpha Seck',
            role: 'Directeur et fondateur',
            socialsnetwork:{
                facebook: '',
                twitter: '',
                linkedin: ''
            }
        },
        {
            // img: 'assets/img/team/4.jpg',
            img: 'assets/img/illustrations/woman.png',
            fullname: 'Seynabou Diouf',
            role: 'Responsable commerciale',
            socialsnetwork: {
                facebook: '',
                twitter: '',
                linkedin: ''
            }
        }
    ];
    const publicUrl = process.env.PUBLIC_URL + '/';

    return <div>
        <Navbar />
        <PageHeader
            headertitle="À propos de ALFAIMMO"
            customclass="mb-5"
            breadcrumbs={['alfaimmo']}
        />
        {/* <AboutV4 /> */}
        <div className="ltn__about-us-area personalized go-top pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 align-self-center">
                        <div className="about-us-img-wrap ltn__img-shape-left ltn__tertiary-color--- about-img-left">
                            <img
                                src={`${publicUrl}assets/img/illustrations/real-estate-agency-2024-04-03-22-08-59-utc-r.jpg`}
                                className='d-lg-none'
                                alt="Illustration"
                                />
                            <img
                                src={`${publicUrl}assets/img/illustrations/real-estate-agency-2024-04-03-22-08-59-utc-r2.jpg`}
                                alt="Illustration"
                                className='d-none d-lg-inline-block'
                            />
                        </div>
                    </div>
                    <div className="col-lg-7 align-self-center">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Qui sommes-nous ?</h6>
                                <h2 className="section-title">Le principal marché de vente et de location de biens immobiliers</h2>
                                <p>Alpha Immo est un acteur incontournable dans la vente et la location de biens immobiliers au Sénégal.<br />
                                    Nous proposons une expertise complète, allant de la vente directe de terrains nus, d'appartements et de 
                                    maisons clé en main, à la location de maisons et d'appartements, afin de répondre aux besoins variés de nos clients.</p>
                            </div>
                            <div className="about-us-info-wrap-inner about-us-info-devide---">
                                <p>Nous nous engageons à faciliter votre projet immobilier en vous offrant un accès direct à des biens de qualité. 
                                    Que vous soyez acheteur ou investisseur, notre équipe est à votre écoute pour vous aider à réaliser vos ambitions.<br />
                                    Chez Alpha Immo, notre priorité est de rendre le processus d'achat et de location aussi simple et efficace que possible. 
                                    Faites confiance à Alpha Immo pour concrétiser vos projets immobiliers au Sénégal.</p>
                            </div>
                            {/* <div className="btn-wrapper animated">
                                <Link to="/about" className="theme-btn-1 btn btn-effect-1 text-uppercase">About Us</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ltn__about-us-area personalized go-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 align-self-center order-lg-2">
                        <div className="about-us-img-wrap ltn__img-shape-right ltn__tertiary-color--- about-img-left">
                            <img
                                src={`${publicUrl}assets/img/illustrations/happy-couple-recommending-real-estate-agency-2023-11-27-05-07-00-utc-r.jpg`}
                                className='d-lg-none'
                                alt="Illustration"
                                />
                            <img
                                src={`${publicUrl}assets/img/illustrations/happy-couple-recommending-real-estate-agency-2023-11-27-05-07-00-utc-r2.jpg`}
                                alt="Illustration"
                                className='d-none d-lg-inline-block'
                            />
                        </div>
                    </div>
                    <div className="col-lg-7 align-self-center order-lg-1">
                        <div className="about-us-info-wrap">
                            <div className="section-title-area ltn__section-title-2--- mb-20">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Vision</h6>
                                <h2 className="section-title">Quel est notre objectif <span>?</span></h2>
                                <p>Notre objectif est de rendre l'immobilier accessible à tous.<br />En supprimant les barrières 
                                    financières, comme l'apport initial, nous permettons à chacun de concrétiser ses projets 
                                    immobiliers, quelle que soit sa situation.
                                </p>
                            </div>
                            <div className="about-us-info-wrap-inner about-us-info-devide---">
                                <p>Nous offrons des solutions de financement simples et adaptées aux besoins de nos clients, 
                                    afin que l'accès à la propriété soit synonyme de sérénité et d'opportunités pour tous.
                                    Avec notre expertise, nous offrons une expérience fluide, des solutions de paiement adaptées et 
                                    un accompagnement personnalisé pour concrétiser vos ambitions en toute sécurité.
                                </p>
                            </div>
                            {/* <div className="btn-wrapper animated">
                                <Link to="/about" className="theme-btn-1 btn btn-effect-1 text-uppercase">About Us</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Services />
        {/* <Features customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" /> */}
        {/* <Team /> */}
        <div className="ltn__team-area pt-115 pb-90 go-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Notre équipe</h6>
                            <h2 className="section-title">Management team</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                {
                    teams.map((m, index) => {
                        return (
                            <div
                                className="col-lg-4 col-sm-6"
                                key={index}
                            >
                                <div className="ltn__team-item ltn__team-item-3---">
                                    <div className="team-img">
                                        <img
                                            src={`${publicUrl}${m.img}`}
                                            alt=""
                                        />
                                    </div>
                                    <div className="team-info">
                                        <h4>
                                            <Link to="/team-details">{m.fullname}</Link>
                                        </h4>
                                        <h6 className="ltn__secondary-color">{m.role}</h6>
                                        <div className="ltn__social-media">
                                            <ul>
                                                <li>
                                                    <a href={m.socialsnetwork.facebook}>
                                                        <i className="fab fa-facebook-f" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={m.socialsnetwork.twitter}>
                                                        <i className="fab fa-twitter" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={m.socialsnetwork.linkedin}>
                                                        <i className="fab fa-linkedin" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
		</div>
        <Testimonial />
        {/* <BlogSlider /> */}
        <Newsletter />
        <Footer />
    </div>
};

export default AlfaImmo;

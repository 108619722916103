import React, { useEffect, useState } from "react";

import { formatNumber, formatPhoneNumber } from "../../../../utils/AssetHelpers";
import RealEstateMap from "../../maps/RealEstateMap";
import { FormFeedback } from "reactstrap";
import { useLoaderSpinnerContext } from "../../../global-components/providers/LoaderSpinnerProvider";
import api from "../../../../services/api";
import useGoTo from "../../../global-components/hooks/useGoTo";
import Swal from "sweetalert2";
import { getBadgeColorByStatus, getStatus } from "../../../../utils/shared";

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const UndividedPlotDetails = ({land}) => {

    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [isValidFullName, setIsValidFullName] = useState(false);
    const [isTouchedForFullName, setIsTouchedForFullName] = useState(false);
    const [isValidAddress, setIsValidAddress] = useState(false);
    const [isTouchedForAddress, setIsTouchedForAddress] = useState(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const [isTouchedForPhoneNumber, setIsTouchedForPhoneNumber] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isTouchedForEmail, setIsTouchedForEmail] = useState(false);
    const [isValidSubject, setIsValidSubject] = useState(false);
    const [isTouchedForSubject, setIsTouchedForSubject] = useState(false);
    const [isValidMessage, setIsValidMessage] = useState(false);
    const [isTouchedForMessage, setIsTouchedForMessage] = useState(false);

    const [reset, setReset] = useState(false);

    useEffect(() => {
        if(reset){
            setFullName('');
            setIsTouchedForFullName(false);
            setAddress('');
            setIsTouchedForAddress(false);
            setPhoneNumber('');
            setIsTouchedForPhoneNumber(false);
            setEmail('');
            setIsTouchedForEmail(false);
            setSubject('');
            setIsTouchedForSubject(false);
            setMessage('');
            setIsTouchedForMessage(false);
            setReset(false);
        }
    },[reset]);

    /**
     * 
     * @returns 
     */
    const isValidData = () => {
        return (
            isValidFullName &&
            isValidAddress &&
            isValidPhoneNumber &&
            isValidEmail &&
            isValidSubject &&
            isValidMessage
        );
    };

    /**
     * 
     * @returns 
     */
    const validateData = () => {
        if(!isValidData()){
            setIsTouchedForFullName(!isValidFullName);
            setIsTouchedForAddress(!isValidAddress);
            setIsTouchedForPhoneNumber(!isValidPhoneNumber);
            setIsTouchedForEmail(!isValidEmail);
            setIsTouchedForSubject(!isValidSubject);
            setIsTouchedForMessage(!isValidMessage)
            return false;
        }
        return true;
    };

    /**
     * 
     * @param name 
     */
    const _setFullName = (name) => {
        setFullName(name);
        setIsValidFullName(name.length > 0);
    };
    
    /**
     * 
     * @param _address 
     */
    const _setAddress = (_address) => {
        setAddress(_address);
        setIsValidAddress(_address.length > 0);
    };

    /**
     * 
     * @param _phoneNumber 
     */
    const _setPhoneNumber = (_phoneNumber) => {
        setPhoneNumber(formatPhoneNumber(_phoneNumber));
        setIsValidPhoneNumber(_phoneNumber.length > 0 &&
            _phoneNumber.match(/(^3[3]|^7[5-80])[ ]?[0-9]{3}([ ]?[0-9]{2}){2}$/) !== null);
    };

    /**
     * 
     * @param _email 
     */
    const _setEmail = (_email) => {
        setEmail(_email);
        setIsValidEmail(_email.length > 0 && _email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null);
    };

    /**
     * 
     * @param _subject 
     */
    const _setSubject = (_subject) => {
        setSubject(_subject);
        setIsValidSubject(_subject.length > 0);
    };

    /**
     * 
     * @param _message 
     */
    const _setMessage = (_message) => {
        setMessage(_message);
        setIsValidMessage(_message.length > 0);
    };

    const goTo = useGoTo();
    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();

    const sendRealEstateAcquisitionRequest = () => {

        // Swal.fire({
        //     text: "Test",
        //     icon: 'success',
        //     // iconColor: '#b02127',
        //     showCancelButton: false,
        //     showConfirmButton: true,
        //     confirmButtonColor: '#7bcb4d', 
        //     // confirmButtonColor: 'var(--bs-success)', 
        //     confirmButtonText: "D'accord !"
        // });
        // return
        if(!validateData()) {
            // showToast(`${t('Veuillez remplir tous les champs')} !`, 'warning', { autoClose: 2000 });
            // showToast('Veuillez remplir tous les champs !', 'warning', { autoClose: 2000 });
            return;
        }
        setShowLoaderSpinner(true);

        const data = {
            "real_estate_id": land.real_estate.id,
            "full_name": fullName,
            "address": address,
            "phone_number": phoneNumber,
            "email": email,
            "subject": subject,
            "message": message,
        };
        api.post('/register-real-estate-acquisition-request', data,
            {
                headers: {
                    // Authorization : `Bearer ${token}`,
                    // 'Content-Type': 'multipart/form-data'
                }
            }
        ).then((res) => {
            if(res.status === 200 && res.data.message){
                setTimeout(() => {
                    Swal.fire({
                        text: res.data.message,
                        icon: 'success',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#7bcb4d', 
                        confirmButtonText: "D'accord !"
                    }).then((r) => {
                        setShowLoaderSpinner(true);
                        goTo('/terrains/parcelles-non-loties');
                        setTimeout(() => {
                            setShowLoaderSpinner(false);
                        }, 1400);
                    });
                    setTimeout(() => {
                        setShowLoaderSpinner(false);
                    }, 400);
                }, 600);
            }
            else{
                setShowLoaderSpinner(false);
                // showToast(res.data.message ? t(res.data.message) : `${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            }
        }).catch((error) => {
            Swal.fire({
                text: error.response.data.message ? error.response.data.message : "Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur",
                icon: 'error',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#b02127', 
                confirmButtonText: "D'accord !"
            });
            setShowLoaderSpinner(false);
            // showToast(error.response.data.message ? t(error.response.data.message) : `${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.dir(error);
        });
    };
    const publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div className="ltn__shop-details-area pb-10">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
                            <div className="ltn__blog-meta">
                                <ul>
                                    <li className="ltn__blog-category">
                                        <span className={`sale-badge ${getBadgeColorByStatus(land.real_estate)}`}>{getStatus(land.real_estate)}</span>
                                    </li>
                                </ul>
                            </div>
                            <h1>{`Terrain nu - lot n° ${land.number}`}</h1>
                            <label>
                                <span className="ltn__secondary-color">
                                    <i className="flaticon-pin" />
                                </span>{" "}
                                {`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                            </label>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-6">
                                    <h4 className="title-2">Description</h4>
                                    <p>{land.real_estate.description}</p>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h4 className="title-2">Modalités</h4>
                                    <p>
                                    {
                                        land.real_estate.terms_and_conditions
                                        ?
                                        land.real_estate.terms_and_conditions.split('.').map((it, index) => <span key={index}><br/>{it}</span>)
                                        : 
                                        "Aucune modalité n'a été définie"
                                    }
                                    </p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-6">
                                    <h4 className="title-2">Caractéristiques</h4>
                                    <div className="property-detail-info-list section-bg-1 clearfix mb-60 d-flex flex-row align-items-stretch align-items-center">
                                        <ul className="personalized px-3 py-2">
                                            <li className="mt-0">
                                                <label>Superficie:</label>{" "}
                                                <span>{parseInt(land.real_estate.superficies)}{" "}m<sup>2</sup></span>
                                            </li>
                                            <li>
                                                <label>Position:</label>{" "}
                                                <span>{land.position === 'CORNER' ? "Angle" : "Simple"}</span>
                                            </li>
                                            <li>
                                                <label>Catégorie:</label>{" "}
                                                <span>{land.category === 'TF' ? "Titre foncier" : "Bail"}</span>
                                            </li>
                                            <li>
                                                <label>Prix:</label>
                                                <span>
                                                    {formatNumber(land.real_estate.price)}{" "}
                                                    <label>FCFA</label>
                                                {
                                                    land.real_estate.status === "RENT" && 
                                                    <label>/Mois</label>
                                                }
                                                </span>
                                                <small>
                                                {
                                                    land.real_estate.monthly_price &&
                                                    <span>{" - "}{formatNumber(land.real_estate.monthly_price)} <label>FCFA</label> <label>/ Mois</label></span>
                                                }
                                                </small>
                                            </li>
                                            <li>
                                                <label>Viabilisé:</label>{" "}
                                                <span>{land.real_estate.serviced? "Oui" : "Non"}</span>
                                            </li>
                                            <li>
                                                <label>Statut:</label>{" "}
                                                <span>{getStatus(land.real_estate)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <h4 className="title-2">Plan</h4>
                                    {/* APARTMENTS PLAN AREA START */}
                                    <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                                        <div className="apartments-plan-img">
                                            <img
                                                // src={publicUrl + "assets/img/others/10.png"}
                                                // src={`${publicUrl}assets/img/lands/LOTISSEMENT-POUT-KHININE-50-PARCELLES.png`}
                                                src={`${publicUrl}assets/img/lands/${land && land.real_estate.municipality_id === 466 ? 'LOTISSEMENT-KOUNOUNE-14-PARCELLES-2' : 'LOTISSEMENT-POUT-KHININE-50-PARCELLES'}.png`}
                                                alt="#"
                                            />
                                        </div>
                                    </div>
                                    {/* APARTMENTS PLAN AREA END */}
                                </div>
                            </div>
                            <h4 className="title-2">Vidéo du terrain</h4>
                            <div
                                className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg-image mb-60"
                                // data-bs-bg={`${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg`}
                                style={{backgroundImage: `url(${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg)`}}
                            >
                                <a
                                    className="ltn__video-icon-2 ltn__video-icon-2-border---"
                                    // href="https://www.youtube.com/embed/eWUxqVFBq74?autoplay=1&showinfo=0"
                                    href="https://www.youtube.com/embed/iBvyMmKBCcA?autoplay=1&showinfo=0"
                                    data-rel="lightcase:myCollection"
                                >
                                    <i className="fa fa-play" />
                                </a>
                            </div>
                            <h4 className="title-2">Emplacement</h4>
                            {/* <div className="property-details-google-map mb-60">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd"
                                    width="100%"
                                    height="100%"
                                    frameBorder={0}
                                    allowFullScreen
                                    aria-hidden="false"
                                    tabIndex={0}
                                    title="#"
                                />
                                <iframe
                                    src="http://localhost:8000/localisation-des-biens-immobiliers"
                                    width="100%"
                                    height="100%"
                                    frameBorder={0}
                                    allowFullScreen
                                    aria-hidden="false"
                                    tabIndex={0}
                                    title="#"
                                />
                                <iframe
                                    src="https://earth.google.com/web/data=MkEKPwo9CiExbzUweDZSV2RCbnA3NS1PbUlkQUtXZ2VxU0Z3UjYzTjUSFgoUMEI5Mjg2RTgzMDMzREIxQjgyRjggAUoICLiO6aICEAE"
                                    width="100%"
                                    height="100%"
                                    frameBorder={0}
                                    allowFullScreen
                                    aria-hidden="false"
                                    tabIndex={0}
                                    title="#"
                                />
                            </div> */}
                            <div className="mb-90">
                                <div className="map-wrapper real-estate-map-wrapper mb-60">
                                    <RealEstateMap realEstate={land.real_estate} zoom={10} />
                                </div>

                                <div className="btn-wrapper text-center">
                                    <a
                                        href="https://earth.google.com/static/multi-threaded/versions/10.65.1.2/index.html?#data=MkEKPwo9CiExbzUweDZSV2RCbnA3NS1PbUlkQUtXZ2VxU0Z3UjYzTjUSFgoUMEI5Mjg2RTgzMDMzREIxQjgyRjggAUoICPWw3doDEAE"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                    >
                                        Voir avec Google Earth
                                    </a>
                                </div>
                            </div>
                            {/* form */}
                            <div className="ltn__comment-reply-area ltn__form-box mb-30">
                                <form>
                                    <h4 className="mt-0">Faire une proposition d'achat</h4>
                                    <div className="mb-30"></div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className={`form-group-wrapper mb-1 ${!isValidFullName && isTouchedForFullName ? 'is-invalid' : ''}`}>
                                                <div className="input-item input-item-name ltn__custom-icon">
                                                    <input
                                                        type="text"
                                                        name="full_name"
                                                        placeholder="Prénom et nom...."
                                                        className="mb-0"
                                                        defaultValue=""
                                                        onBlur={() => setIsTouchedForFullName(true)}
                                                        onInput={(e) => _setFullName(e.currentTarget.value)}
                                                    />
                                                </div>
                                                <FormFeedback invalid='true' className="ps-1">{'Veuillez renseigner ce champ'} !</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className={`form-group-wrapper mb-1 ${!isValidAddress && isTouchedForAddress ? 'is-invalid' : ''}`}>
                                                <div className="input-item input-item-address ltn__custom-icon">
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        placeholder="Adresse...."
                                                        className="mb-0"
                                                        defaultValue=""
                                                        onBlur={() => setIsTouchedForAddress(true)}
                                                        onInput={(e) => _setAddress(e.currentTarget.value)}
                                                    />
                                                </div>
                                                <FormFeedback invalid='true' className="ps-1">{'Veuillez renseigner ce champ'} !</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className={`form-group-wrapper mb-1 ${!isValidPhoneNumber && isTouchedForPhoneNumber ? 'is-invalid' : ''}`}>
                                                <div className="input-item input-item-phone ltn__custom-icon">
                                                    <input
                                                        type="tel"
                                                        name="phone_number"
                                                        placeholder="Numéro de téléphone...."
                                                        className="mb-0"
                                                        defaultValue=""
                                                        onBlur={() => setIsTouchedForPhoneNumber(true)}
                                                        onInput={(e) => _setPhoneNumber(e.currentTarget.value)}
                                                    />
                                                </div>
                                                <FormFeedback invalid='true' className="ps-1">{!phoneNumber.length ? 'Veuillez renseigner le numéro de téléphone' : 'Numéro de téléphone invalide'} !</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className={`form-group-wrapper mb-1 ${!isValidEmail && isTouchedForEmail ? 'is-invalid' : ''}`}>
                                                <div className="input-item input-item-email ltn__custom-icon">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Adress e-mail...."
                                                        className="mb-0"
                                                        defaultValue=""
                                                        onBlur={() => setIsTouchedForEmail(true)}
                                                        onInput={(e) => _setEmail(e.currentTarget.value)}
                                                    />
                                                </div>
                                                <FormFeedback invalid='true' className="ps-1">{!email.length ? 'Veuillez renseigner ce champ' : 'Adresse e-mail invalide'} !</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className={`form-group-wrapper mb-1 ${!isValidSubject && isTouchedForSubject ? 'is-invalid' : ''}`}>
                                                <div className="input-item input-item-subject ltn__custom-icon">
                                                    <input
                                                        type="text"
                                                        name="subject"
                                                        placeholder="Objet...."
                                                        className="mb-0"
                                                        defaultValue=""
                                                        onBlur={() => setIsTouchedForSubject(true)}
                                                        onInput={(e) => _setSubject(e.currentTarget.value)}
                                                    />
                                                </div>
                                                <FormFeedback invalid='true' className="ps-1">{'Veuillez renseigner ce champ'} !</FormFeedback>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className={`form-group-wrapper mb-1 ${!isValidMessage && isTouchedForMessage ? 'is-invalid' : ''}`}>
                                                <div className="input-item input-item-textarea ltn__custom-icon">
                                                    <textarea
                                                        name="message"
                                                        rows={8}
                                                        placeholder="Message...."
                                                        // defaultValue={""}
                                                        className="h-100 mb-0"
                                                        defaultValue=""
                                                        onBlur={() => setIsTouchedForMessage(true)}
                                                        onInput={(e) => _setMessage(e.currentTarget.value)}
                                                    />
                                                </div>
                                                <FormFeedback invalid='true' className="ps-1 mt-0">{'Veuillez renseigner ce champ'} !</FormFeedback>
                                            </div>
                                        </div>
                                        {/* <label className="mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label> */}
                                        <div className="btn-wrapper">
                                            <button
                                                type="button"
                                                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                                onClick={sendRealEstateAcquisitionRequest}
                                            >
                                                Soumettre
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* form */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UndividedPlotDetails;

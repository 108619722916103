import React from "react";

import { LayerGroup, LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../utils/AssetHelpers";
import { useDataContext } from "../../../data/providers/DataProvider";
const { BaseLayer } = LayersControl;

const RealEstatesMap = () => {

    const { lands, houses, apartments } = useDataContext();

    // const customIcon = new L.Icon({
    //     iconUrl: require("./location.svg").default,
    //     // iconUrl: "./location.svg",
    //     iconSize: new L.Point(40, 47)
    // });
    return (
        <MapContainer
            className=""
            // center={[14.4994539, -14.445561388888889]}
            center={[14.692778, -17.446667]}
            zoom={10}
            scrollWheelZoom={false}
        >
            <LayersControl>
                <BaseLayer name="OpenStreetMap.Mapnik" checked>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Alidade Smooth">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}"
                        attribution="&copy; <a href=&quot;https://www.stadiamaps.com/&quot; target=&quot;_blank&quot;>Stadia Maps</a> &copy; <a href=&quot;https://openmaptiles.org/&quot; target=&quot;_blank&quot;>OpenMapTiles</a> &copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia OSM Bright">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Outdoors">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Alidade Satellite">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="jpg"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Azure Road">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="Azure Hybrid">
                    <LayerGroup>
                        {/* <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.darkgrey&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='en-US'
                            maxZoom={20}
                        /> */}
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="Azure Satellite">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="ArcGis Hybrid">
                    <LayerGroup>
                        <TileLayer
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                            attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="ArcGis Satellite">
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                        // attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
            </LayersControl>
            {
                lands.map((land, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[land.real_estate.latitude, land.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{land.real_estate.wording}</h6>
                                    <p>{land.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(land.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {land.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
            {
                houses.map((house, index) => {
                    return (
                        <Marker
                            key={index}
                            // icon={customIcon}
                            position={[house.real_estate.latitude, house.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{house.real_estate.wording}</h6>
                                    <p>{house.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(house.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {house.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
            {
                apartments.map((apartment, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[apartment.real_estate.latitude, apartment.real_estate.longitude]}
                            // icon={customIcon}
                        >
                            <Popup>
                                <div>
                                    <h6>{apartment.real_estate.wording}</h6>
                                    <p>{apartment.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(apartment.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {apartment.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
            {/* <Marker position={[14.75, -17.43]}></Marker>
            <Marker position={[14.75, -17.46]}></Marker>
            <Marker position={[14.77, -17.37]}></Marker>
            <Marker position={[14.76, -17.35]}></Marker>
            <Marker position={[14.79, -16.93]}></Marker>
            <Marker position={[14.79, -16.94]}></Marker>
            <Marker position={[14.73, -17.08]}></Marker>
            <Marker position={[14.73, -17.07]}></Marker>
            <Marker position={[14.81, -17.02]}></Marker>
            <Marker position={[14.81, -17.19]}></Marker>
            <Marker position={[14.82, -17.26]}></Marker>
            <Marker position={[14.75, -17.21]}></Marker> */}
        </MapContainer>
    );
};

export default RealEstatesMap;

import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Sidebar from "./Sidebar";
import { useDataContext } from "../../../data/providers/DataProvider";
import { formatNumber } from "../../../utils/AssetHelpers";
import { useResearchDataContext } from "../providers/ResearchDataProvider";
import NoApartment from "./NoApartment";
import usePagination from "../../global-components/hooks/usePagination";
import Pagination from "../../global-components/ui/Pagination";
import { useLoaderSpinnerContext } from "../../global-components/providers/LoaderSpinnerProvider";
import { getBadgeColorByStatus, getStatus } from "../../../utils/shared";

const Content = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { apartments } = useDataContext();
    const {
        apartmentCategory,
        apartmentDepartment,
        apartmentMunicipality,
        apartmentRegion,
        apartmentSuperficiesRange,
        apartmentLevel,
        apartmentRoomsNumber,
        apartmentToiletsNumber,
        apartmentPriceRange
    } = useResearchDataContext();

    const publicUrl = process.env.PUBLIC_URL + "/";
    const itemsPerPage = [12, 20, 30, 50, 100];
    const sortingElements = [
        {
            value: 'default',
            wording: "Défaut"
        },
        {
            value: 'price-asc',
            wording: "Prix : du plus bas au plus élevé"
        },
        {
            value: 'price-desc',
            wording: "Prix : du plus élevé au plus bas"
        },
    ];

    const [filteredAppartements, setFilteredAppartements] = useState([]);
    const [searchedText, setSearchText] = useState('');
    const [fromSearch, setFromSearch] = useState(false);

    /**
     * 
     * @param apartment 
     * @returns 
     */
    const checkSearchedText = useCallback((apartment) => {

        return (apartment.real_estate.wording.toLowerCase().indexOf(searchedText) > -1 ||
                apartment.real_estate.description.toLowerCase().indexOf(searchedText) > -1 ||
                apartment.real_estate.superficies.toLowerCase().indexOf(searchedText) > -1 ||
                `${apartment.real_estate.price}`.toLowerCase().indexOf(searchedText) > -1);

    }, [searchedText]);

    useEffect(() => {
        let f_apartments = apartments;
        setFromSearch(false);

        if(apartmentCategory){
            f_apartments = f_apartments.filter((a) => a.real_estate.status === apartmentCategory);
        }
        if(apartmentRegion){
            f_apartments = f_apartments.filter((a) => a.real_estate.region_id === apartmentRegion.id);
        }
        if(apartmentMunicipality){
            f_apartments = f_apartments.filter((a) => a.real_estate.municipality_id === apartmentMunicipality.id);
        }
        if(apartmentLevel){
            f_apartments = f_apartments.filter((a) => parseInt(a.level) === parseInt(apartmentLevel.value));
        }
        if(apartmentRoomsNumber){
            f_apartments = f_apartments.filter((a) => a.rooms_number === parseInt(apartmentRoomsNumber.value));
        }
        if(apartmentToiletsNumber){
            f_apartments = f_apartments.filter((a) => a.toilets_number === parseInt(apartmentToiletsNumber.value));
        }
        if(apartmentPriceRange){
            const apartmentPriceRangeLimits = apartmentPriceRange.split('-');
            f_apartments = f_apartments.filter((a) => a.real_estate.price >= parseInt(apartmentPriceRangeLimits[0]) && a.real_estate.price <= parseInt(apartmentPriceRangeLimits[1]));
        }
        if(searchedText.length > 0){
            f_apartments = f_apartments.filter(checkSearchedText);
            setFromSearch(true);
        }
        setFilteredAppartements(f_apartments);

    }, [
        apartments,
        apartmentRegion,
        apartmentMunicipality,
        apartmentPriceRange,
        apartmentCategory,
        apartmentLevel,
        apartmentRoomsNumber,
        apartmentToiletsNumber,
        searchedText,
        checkSearchedText
    ]);

    const [rowsPerPage, setRowsPerPage] = useState(Number(sessionStorage.getItem('apartmentsPagination')??12));

    useEffect(() => {
        const rows = sessionStorage.getItem('apartmentsPagination');
        setRowsPerPage(rows !== null ? Number(rows) : 12);
    }, []);

    const [curentPageNumber, setCurrentPageNumber] = useState(1);

    const { pageData, pageCount } = usePagination(filteredAppartements, curentPageNumber, rowsPerPage);

    useEffect(() => {
        if(curentPageNumber > pageCount){
            setCurrentPageNumber(pageCount);
        }
    }, [pageCount, curentPageNumber]);

    return (
        <div>
            <div className="ltn__product-area ltn__product-gutter">
                <div className="container-fluid px-lg-4 px-xl-5">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-xl-3 mb-100">
                            <Sidebar />
                        </div>
                        <div className="col-md-8 col-lg-8 col-xl-9 mb-100">
                            <div className="ltn__shop-options d-flex flex-wrap align-items-center justify-content-between">
                                <ul className="justify-content-start">
                                    <li className="d-none">
                                        <div className="showing-product-number text-right">
                                            <span>Showing 1–12 of 18 results</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center gap-2">
                                           <label>Trier par:</label>
                                            <div className="short-by text-center">
                                                <select className="nice-select">
                                                {
                                                    sortingElements.map((element, index) => {
                                                        return (
                                                            <option key={index} value={element.value}>{element.wording}</option>
                                                        );
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="justify-content-start">
                                    <li>
                                        <div className="ltn__grid-list-tab-menu ">
                                            <div className="nav">
                                                <a
                                                    className="active show"
                                                    data-bs-toggle="tab"
                                                    href="#liton_product_grid"
                                                >
                                                    <i className="fas fa-th-large" />
                                                </a>
                                                <a
                                                    data-bs-toggle="tab"
                                                    href="#liton_product_list"
                                                >
                                                    <i className="fas fa-list" />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade active show"
                                    id="liton_product_grid"
                                >
                                    <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* Search Widget */}
                                                <div className="ltn__search-widget mb-30">
                                                    <form>
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Rerchercher..."
                                                            defaultValue=""
                                                            onInput={(e) => setSearchText(e.currentTarget.value)}
                                                        />
                                                        <button type="submit">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                {/* Search Widget */}
                                            </div>
                                            {
                                                !pageData.length
                                                ?
                                                <NoApartment fromSearch={fromSearch} />
                                                :
                                                pageData.map((apartment, index) => {
                                                    return (
                                                        // {/* ltn__product-item */}
                                                        <div key={index} className="col-12 col-sm-6 col-xxl-4">
                                                        {/* <div className="col-12"> */}
                                                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                                                <div className="product-img go-top">
                                                                    <Link
                                                                        to={`/appartements/${apartment.id}`}
                                                                        onClick={() => setShowLoaderSpinner(true)}
                                                                    >
                                                                        <img
                                                                            src={`${publicUrl}assets/img/apartments/${apartment.id}/${apartment.real_estate.images[0].file_name}`}
                                                                            alt="Illustration"
                                                                        />
                                                                    </Link>
                                                                    <div className="product-badge">
                                                                        <ul>
                                                                            <li className={`sale-badge ${getBadgeColorByStatus(apartment.real_estate)}`}>{getStatus(apartment.real_estate)}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="product-img-location-gallery px-3">
                                                                        <div className="product-img-location">
                                                                            <p
                                                                                className="m-0 text-white text-truncate cursor-default"
                                                                                title={`${apartment.real_estate.municipality.name}, ${apartment.real_estate.street}`}
                                                                            >
                                                                            <small>
                                                                                <i className="flaticon-pin" />{" "}
                                                                                {`${apartment.real_estate.municipality.name}, ${apartment.real_estate.street}`}
                                                                            </small>
                                                                            </p>
                                                                        </div>
                                                                    {
                                                                        apartment.real_estate.belongs_to_alphaimmo &&
                                                                        <div className="real-estate-agent">
                                                                            <div className="agent-img bg-white rounded-circle p-1 m-0">
                                                                                <a href="/alfaimmo">
                                                                                    <img
                                                                                        src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                                        alt="Mascotte ALFAIMMO"
                                                                                        width={32}
                                                                                        title="ALFAIMMO"
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                        {/* <div className="product-img-gallery go-top">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/appartements/${apartment.id}`}>
                                                                                        <i className="fas fa-camera" />{" "}
                                                                                        4
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link to={`/appartements/${apartment.id}`}>
                                                                                        <i className="fas fa-film" />{" "}
                                                                                        2
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-price fs-6">
                                                                        <small>
                                                                            <span>
                                                                                {formatNumber(apartment.real_estate.price)}{" "}<label>FCFA</label>
                                                                            {
                                                                                apartment.real_estate.status === "RENT" && 
                                                                                <label>/Mois</label>
                                                                            }
                                                                            </span>
                                                                        {
                                                                            apartment.real_estate.monthly_price &&
                                                                            <span>{" - "}{formatNumber(apartment.real_estate.monthly_price)} <label>FCFA</label> <label>/ Mois</label></span>
                                                                        }
                                                                        </small>
                                                                    </div>
                                                                    <h3 className="product-title go-top fs-6">
                                                                        <Link to={`/appartements/${apartment.id}`}>Appartement</Link>
                                                                    </h3>
                                                                    <div className="product-description">
                                                                        <p className="clamped-text clamped-text-1">{apartment.real_estate.description}</p>
                                                                    </div>
                                                                    <ul className="ltn__list-item-2 ltn__list-item-2-before personalized row">
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Supercifie:</span>
                                                                            <span>{parseInt(apartment.real_estate.superficies)} m<sup>2</sup></span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Niveau:</span>
                                                                            <span>{apartment.level}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Chambres:</span>
                                                                            <span>{apartment.rooms_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Salles de bain:</span>
                                                                            <span>{apartment.bathrooms_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Toilettes:</span>
                                                                            <span>{apartment.toilets_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <label>Position du bâtiment:</label>{" "}
                                                                            <span>{apartment.house_position === 'CORNER' ? "Angle" : "Simple"}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        // {/* ltn__product-item */}
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="liton_product_list"
                                >
                                    <div className="ltn__product-tab-content-inner ltn__product-list-view">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* Search Widget */}
                                                <div className="ltn__search-widget mb-30">
                                                    <form action="#">
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Rerchercher..."
                                                            defaultValue=""
                                                            onInput={(e) => setSearchText(e.currentTarget.value)}
                                                        />
                                                        <button type="submit">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                {/* Search Widget */}
                                            </div>
                                            {
                                                !pageData.length
                                                ?
                                                <NoApartment fromSearch={fromSearch} />
                                                :
                                                pageData.map((apartment, index) => {
                                                    return (
                                                        // {/* ltn__product-item */}
                                                        <div key={index} className="col-lg-12">
                                                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                                                <div className="product-img go-top">
                                                                    <Link
                                                                        to={`/appartements/${apartment.id}`}
                                                                        onClick={() => setShowLoaderSpinner(true)}
                                                                    >
                                                                        <img
                                                                            src={`${publicUrl}assets/img/apartments/${apartment.id}/${apartment.real_estate.images[0].file_name}`}
                                                                            alt="Illustration"
                                                                        />
                                                                    </Link>
                                                                    <div className="product-badge">
                                                                        <ul>
                                                                            <li className={`sale-badge ${getBadgeColorByStatus(apartment.real_estate)}`}>{getStatus(apartment.real_estate)}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="product-img-location-gallery px-3">
                                                                        <div className="product-img-location">
                                                                            <p
                                                                                className="m-0 text-white text-truncate cursor-default"
                                                                                title={`${apartment.real_estate.municipality.name}, ${apartment.real_estate.street}`}
                                                                            >
                                                                            <small>
                                                                                <i className="flaticon-pin" />{" "}
                                                                                {`${apartment.real_estate.municipality.name}, ${apartment.real_estate.street}`}
                                                                            </small>
                                                                            </p>
                                                                        </div>
                                                                    {
                                                                        apartment.real_estate.belongs_to_alphaimmo &&
                                                                        <div className="real-estate-agent">
                                                                            <div className="agent-img bg-white rounded-circle p-1 m-0">
                                                                                <a href="/alfaimmo">
                                                                                    <img
                                                                                        src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                                        alt="Mascotte ALFAIMMO"
                                                                                        width={32}
                                                                                        title="ALFAIMMO"
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                        {/* <div className="product-img-gallery go-top">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/appartements/${apartment.id}`}>
                                                                                        <i className="fas fa-camera" />{" "}
                                                                                        4
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link to={`/appartements/${apartment.id}`}>
                                                                                        <i className="fas fa-film" />{" "}
                                                                                        2
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-price fs-6">
                                                                        <small>
                                                                            <span>
                                                                                {formatNumber(apartment.real_estate.price)}{" "}<label>FCFA</label>
                                                                            {
                                                                                apartment.real_estate.status === "RENT" && 
                                                                                <label>/Mois</label>
                                                                            }
                                                                            </span>
                                                                        {
                                                                            apartment.real_estate.monthly_price &&
                                                                            <span>{" - "}{formatNumber(apartment.real_estate.monthly_price)} <label>FCFA</label> <label>/ Mois</label></span>
                                                                        }
                                                                        </small>
                                                                    </div>
                                                                    <h3 className="product-title go-top fs-6">
                                                                        <Link to={`/appartements/${apartment.id}`}>Appartement</Link>
                                                                    </h3>
                                                                    <div className="product-description">
                                                                        <p className="clamped-text clamped-text-1">{apartment.real_estate.description}</p>
                                                                    </div>
                                                                    <ul className="ltn__list-item-2 ltn__list-item-2-before personalized row">
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Supercifie:</span>
                                                                            <span>{parseInt(apartment.real_estate.superficies)} m<sup>2</sup></span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Niveau:</span>
                                                                            <span>{apartment.level} étage</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Chambres:</span>
                                                                            <span>{apartment.rooms_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Salles de bain:</span>
                                                                            <span>{apartment.bathrooms_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Toilettes:</span>
                                                                            <span>{apartment.toilets_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <label>Position du bâtiment:</label>{" "}
                                                                            <span>{apartment.house_position === 'CORNER' ? "Angle" : "Simple"}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        // {/* ltn__product-item */}
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__pagination-area text-center">
                                <div className="ltn__pagination">
                                    {/* begin::pagination */}
                                    <div className="pagination-box-wrapper mt-5">
                                        <Pagination
                                            id='apartmentsPagination'
                                            pageCount={pageCount}
                                            currentPageNumber={curentPageNumber}
                                            setCurrentPageNumber={setCurrentPageNumber}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    </div>
                                    {/* end::pagination */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;

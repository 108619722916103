import React from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";

// import "./SlideShow.css";

// import { WOW } from "wowjs";

/**
 * 
 * @returns 
 */
const HomeTopSlides = () => {

    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
        <div
            id="homeTopSlides"
            className="home-sildes ltn__slider-area ltn__slider-3 ltn__tertiary-bg section-bg-2 mt-5"
        >
            <Slider
                autoplay={true}
                autoplaySpeed={10000}
                pauseOnHover={false}
                pauseOnFocus={false}
                arrows={false}
                dots={false}
                fade={true}
                cssEase="linear"
                infinite={true}
                speed={300}
                slidesToShow={1}
                slidesToScroll={1}
                prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                responsive={[
                    {
                        breakpoint: 1200,
                        settings: {
                            arrows: false,
                            dots: false,
                        }
                    }
                ]}
                // className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1"
            >
                {/* ltn__slide-item */}
                <div className="slide-item d-flex align-items-center justify-content-center">
                    <div className="slide-item-info-inner ltn__slide-animation d-flex flex-wrap align-items-center justify-content-center gap-3 gap-sm-4 m-0">
                        <div className="slide-brief animated">
                            <p className="mb-0">Achetez un bien à Pout pour seulement <span className="ltn__secondary-bg-- white-bg ltn__secondary-color text-nowrap py-2 px-2 ms-2 fw-semibold">125.000 FCFA par mois !</span></p>
                        </div>
                        <div className="btn-wrapper animated go-top mt-0">
                            <Link
                                to="/terrains/lotissements/1"
                                className="theme-btn-3 btn btn-effect-1 py-2 px-4"
                            >
                                J'en profite
                            </Link>
                        </div>
                    </div>
                </div>
                {/* ltn__slide-item */}
                <div className="slide-item d-flex align-items-center justify-content-center">
                    <div className="slide-item-info-inner ltn__slide-animation flex-wrap d-flex align-items-center justify-content-center gap-3 gap-lg-4 m-0">
                        <div className="slide-brief animated">
                            <p className="mb-0">À 10 minutes de Dakar, acquérez votre maison à Cité Maniang Seck <span className="ltn__secondary-bg-- white-bg ltn__secondary-color text-nowrap py-2 px-2 ms-2 fw-semibold">à partir de 40 millions FCFA</span></p>
                        </div>
                        <div className="btn-wrapper animated go-top mt-0">
                            <Link
                                to="/terrains/lotissements/2"
                                className="theme-btn-3 btn btn-effect-1 py-2 px-4"
                            >
                                J'en profite
                            </Link>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default HomeTopSlides;

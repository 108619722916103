import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import TopBar from "./Topbar";
// import { useDataContext } from "../../data/providers/DataProvider";

/**
 * 
 * @returns 
 */
const Navbar = () => {

    const publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";

    // const { subdivisions } = useDataContext();

    const $ = window.$;
    const $body = $('body');

    useEffect(() => {

        (function () {
            const $ltn__utilizeToggle = $('.ltn__utilize-toggle'),
                $ltn__utilize = $('.ltn__utilize'),
                $ltn__utilizeOverlay = $('.ltn__utilize-overlay'),
                $mobileMenuToggle = $('.mobile-menu-toggle');
            $ltn__utilizeToggle.on('click', function (e) {

                e.preventDefault();
                const $this = $(this),
                    $target = $this.attr('href');
                $body.addClass('ltn__utilize-open');
                $($target).addClass('ltn__utilize-open');
                $ltn__utilizeOverlay.fadeIn();
                if ($this.parent().hasClass('mobile-menu-toggle')) {
                    $this.addClass('close');
                }
            });
            $('.ltn__utilize-close, .ltn__utilize-overlay').on('click', function (e) {
                e.preventDefault();
                $body.removeClass('ltn__utilize-open');
                $ltn__utilize.removeClass('ltn__utilize-open');
                $ltn__utilizeOverlay.fadeOut();
                $mobileMenuToggle.find('a').removeClass('close');
            });
        })();
    }, [$, $body]);

    /**
     * 
     * @param {*} el 
     */
    const toggleSubMenu = (el) => {
        var $this = $(el);

        if ($this.siblings('ul:visible').length) {
            $this.parent('li').removeClass('active');
            $this.siblings('ul').slideUp();
            $this.parent('li').find('li').removeClass('active');
            $this.parent('li').find('ul:visible').slideUp();
        } else {
            $this.parent('li').addClass('active');
            $this.closest('li').siblings('li').removeClass('active').find('li').removeClass('active');
            $this.closest('li').siblings('li').find('ul:visible').slideUp();
            $this.siblings('ul').addClass('.show').slideDown();
        }
    };

    return (
        <div>
            {/* <header className={"ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- "}> */}
            {/* <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4---"> */}
            <header className={"ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- "}>
                {/* ltn__header-top-area start */}
                <TopBar />
                {/* ltn__header-top-area end */}
                {/* ltn__header-middle-area start */}
                <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white bg-white-50"> 
                    {/* style={{background: 'rgba(255,255,255,1)', paddingBottom: '10px', paddingTop:'10px'}}> */}
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="site-logo-wrap">
                                    <div className="site-logo go-top">
                                        <Link to="/">
                                            <img
                                                src={`${publicUrl}assets/img/logos/logo.png`}
                                                width="100"
                                                alt="Logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="get-support clearfix d-none">
                                        <div className="get-support-icon">
                                            <i className="icon-call" />
                                        </div>
                                        <div className="get-support-info">
                                            <h6>Obtenir de l'aide</h6>
                                            <h4>
                                                <a href="tel:+221786586868">(+221) 78 658 68 68</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col header-menu-column menu-color-whitee">
                                <div className="header-menu d-none d-xl-block go-top">
                                    <nav>
                                        <div className="ltn__main-menu">
                                            <ul>
                                                <li>
                                                    <Link to="/accueil">Accueil</Link>
                                                </li>
                                                <li className="menu-icon">
                                                    <button type="button">Biens immobiliers</button>
                                                    <ul className="sub-menu">
                                                        <li>
                                                            {/* <Link to="/terrains">Terrains</Link> */}
                                                            <button type="button">Terrains</button>
                                                            <ul>
                                                                <li>
                                                                    <Link to="/terrains/lotissements">Lotissements</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="/terrains/parcelles-non-loties">Parcelles non loties</Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <Link to="/maisons">Maisons</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/appartements">Appartements</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <Link to="/alfaimmo">Alfaimmo</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact">Contact</Link>
                                                </li>
                                                <li className="special-link">
                                                    <button
                                                        class="btn btn-effect-1 btn-search"
                                                        type="button"
                                                        data-bs-toggle="offcanvas"
                                                        data-bs-target="#searchPanelRight"
                                                        aria-controls="searchPanelRight"
                                                    >
                                                        <i className="flaticon-loupe" />
                                                    </button>
                                                </li>
                                                <li className="special-link">
                                                    <Link to="/localisation-des-biens-immobiliers">Localiser nos biens</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="col--- ltn__header-options ltn__header-options-2 ">
                                {/* Mobile Menu Button */}
                                <div className="mobile-menu-toggle d-xl-none">
                                    <a
                                        type="button"
                                        href="#ltn__utilize-mobile-menu"
                                        className="ltn__utilize-toggle"
                                    >
                                        <svg viewBox="0 0 800 600">
                                            <path
                                                d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                id="top"
                                            />
                                            <path
                                                d="M300,320 L540,320"
                                                id="middle"
                                            />
                                            <path
                                                d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                id="bottom"
                                                transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__header-middle-area end */}
            </header>
            <div
                id="ltn__utilize-mobile-menu"
                className="ltn__utilize ltn__utilize-mobile-menu"
            >
                <div className="ltn__utilize-menu-inner ltn__scrollbar">
                    <div className="ltn__utilize-menu-head">
                        <div className="site-logo">
                            <Link to="/">
                                <img
                                    src={`${publicUrl}assets/img/logos/logo.png`}
                                    width="100"
                                    alt="Logo"
                                />
                            </Link>
                        </div>
                        <button className="ltn__utilize-close">×</button>
                    </div>
                    {/* <div className="ltn__utilize-menu-search-form">
                        <form action={"#"}>
                            <input
                                type="text"
                                placeholder="Rechercher..."
                            />
                            <button>
                                <i className="fas fa-search" />
                            </button>
                        </form>
                    </div> */}
                    <div className="ltn__utilize-menu">
                        <ul>
                            <li>
                                <Link to="/accueil">Accueil</Link>
                            </li>
                            <li>
                                <span className="menu-expand" onClick={(e) => {toggleSubMenu(e.currentTarget)}}></span>
                                <button type="button" onClick={(e) => {toggleSubMenu(e.currentTarget)}}>Biens immobiliers</button>
                                <ul className="sub-menu">
                                    <li>
                                        <span className="menu-expand" onClick={(e) => {toggleSubMenu(e.currentTarget)}}></span>
                                        <button type="button" onClick={(e) => {toggleSubMenu(e.currentTarget)}}>Terrains</button>
                                        <ul className="sub-menu">
                                            <li>
                                                <Link to="/terrains/lotissements">Lotissements</Link>
                                            </li>
                                            <li>
                                                <Link to="/terrains/parcelles-non-loties">Parcelles non loties</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <Link to="/maisons">Maisons</Link>
                                    </li>
                                    <li>
                                        <Link to="/appartements">Appartements</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/alfaimmo">Alfaimmo</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                            <li className="special-link ms-0">
                                <button
                                    class="btn btn-effect-1 btn-search ltn__utilize-close"
                                    type="button"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#searchPanelRight"
                                    aria-controls="searchPanelRight"
                                >
                                    <i className="flaticon-loupe" />
                                    {/* <span>Rechercher un bien</span> */}
                                </button>
                            </li>
                            <li className="special-link ms-0">
                                <Link to="/localisation-des-biens-immobiliers" className="d-inline-block">Localiser nos biens</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;

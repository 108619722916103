import React from "react";

import L from "leaflet";
import { LayerGroup, LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../utils/AssetHelpers";

import 'azure-maps-control/dist/atlas.min.css';

const { BaseLayer } = LayersControl;

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const RealEstateMap = ({realEstate, zoom = 13}) => {

    const customIcon = new L.Icon({
        iconUrl: require("./location.svg").default,
        iconSize: new L.Point(40, 47)
    });

    return (
        <MapContainer
            className="real-estate-map-container"
            center={[realEstate.latitude, realEstate.longitude]}
            zoom={zoom}
            attributionControl={true}
            inertia={true}
            scrollWheelZoom={false}
        >
            <LayersControl>
                <BaseLayer name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
                {/* <BaseLayer name="Stadia Alidade Smooth">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}"
                        attribution="&copy; <a href=&quot;https://www.stadiamaps.com/&quot; target=&quot;_blank&quot;>Stadia Maps</a> &copy; <a href=&quot;https://openmaptiles.org/&quot; target=&quot;_blank&quot;>OpenMapTiles</a> &copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia OSM Bright">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Outdoors">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Alidade Satellite">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="jpg"
                        language='fr-FR'
                    />
                </BaseLayer> */}
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Azure Road">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="Azure Hybrid" checked>
                    <LayerGroup>
                        {/* <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.darkgrey&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='en-US'
                            maxZoom={20}
                        /> */}
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="Azure Satellite">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="ArcGis Hybrid">
                    <LayerGroup>
                        <TileLayer
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                            attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="ArcGis Satellite">
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                        // attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
            </LayersControl>
            <Marker
                position={[realEstate.latitude, realEstate.longitude]}
                icon={customIcon}
            >
                {/* <Pane name="purple-rectangle">
                    <Rectangle bounds={[[0.1, 0.2], [realEstate.latitude, realEstate.longitude]]} pathOptions={{ color: 'purple' }} />
                </Pane> */}
                <Popup>
                    <div>
                        <h6>{realEstate.wording}</h6>
                        <p>{realEstate.description}</p>
                        <div>
                            <label>Prix:</label>
                            <span>
                                {formatNumber(realEstate.price)}{" "}
                                <label>FCFA</label>
                                {realEstate.status === "RENT" && (
                                    <label>/Month</label>
                                )}
                            </span>
                        </div>
                    </div>
                </Popup>
            </Marker>
        </MapContainer>
    );
};

export default RealEstateMap;

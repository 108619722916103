import React from "react";
import { Link } from "react-router-dom";

import parse from "html-react-parser";
import { useDataContext } from "../../../../data/providers/DataProvider";
import { formatNumber } from "../../../../utils/AssetHelpers";
import { getBadgeColorByStatus, getStatus } from "../../../../utils/shared";
import Slider from "react-slick";

/**
 * 
 * @param {*} house 
 */
export const getHousesSliderItem = (house) => {

    const publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div className="ltn__product-item ltn__product-item-4 text-center---">
            <div className="product-img go-top">
                <Link to={`/maisons/${house.id}`}>
                    <img
                        src={`${publicUrl}assets/img/houses/${house.id}/${house.real_estate.images[0].file_name}`}
                        alt="Illustration"
                    />
                </Link>
                <div className="product-badge">
                    <ul>
                        <li className={`sale-badge ${getBadgeColorByStatus(house.real_estate)}`}>{getStatus(house.real_estate)}</li>
                    </ul>
                </div>
                <div className="product-img-location-gallery px-3">
                    <div className="product-img-location">
                        <p
                            className="m-0 text-white text-truncate cursor-default"
                            title={`${house.real_estate.municipality.name}, ${house.real_estate.street}`}
                        >
                        <small>
                            <i className="flaticon-pin" />{" "}
                            {`${house.real_estate.municipality.name}, ${house.real_estate.street}`}
                        </small>
                        </p>
                    </div>
                {
                    house.real_estate.belongs_to_alphaimmo &&
                    <div className="real-estate-agent">
                        <div className="agent-img bg-white rouded-circle p-1 m-0">
                            <a href="/alfaimmo">
                                <img
                                    src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                    alt="Mascotte ALFAIMMO"
                                    width={32}
                                    title="ALFAIMMO"
                                />
                            </a>
                        </div>
                    </div>
                }
                    {/* <div className="product-img-gallery go-top">
                        <ul>
                            <li>
                                <Link to={`/maisons/${house.id}`}>
                                    <i className="fas fa-camera" />{" "}
                                    {house.real_estate.images.length}
                                </Link>
                            </li>
                            <li>
                                <Link to={`/maisons/${house.id}`}>
                                    <i className="fas fa-film" />{" "}
                                    2
                                </Link>
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            <div className="product-info">
                <div className="product-price fs-6">
                    <small>
                        <span>
                            {formatNumber(house.real_estate.price)}{" "}<label>FCFA</label>
                        {
                            house.real_estate.status === "RENT" && 
                            <label>/Mois</label>
                        }
                        </span>
                    {
                        house.real_estate.monthly_price &&
                        <span>{" - "}{formatNumber(house.real_estate.monthly_price)} <label>FCFA</label> <label>/ Mois</label></span>
                    }
                    </small>
                </div>
                <h3 className="product-title go-top fs-6">
                    <Link to={`/maisons/${house.id}`}>{`Maison - villa n° ${house.villa_number}`}</Link>
                </h3>
                <div className="product-description">
                    <p className="clamped-text clamped-text-1">{house.real_estate.description}</p>
                </div>
                <ul className="ltn__list-item-2 ltn__list-item-2-before personalized row">
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Supercifie:</span>
                        <span>{parseInt(house.real_estate.superficies)} m<sup>2</sup></span>
                    </li>
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Chambres:</span>
                        <span>{house.rooms_number}</span>
                    </li>
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Salles de bain:</span>
                        <span>{house.bathrooms_number}</span>
                    </li>
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Toilettes:</span>
                        <span>{house.toilets_number}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

/**
 * 
 * @returns 
 */
const HousesSlider = () => {

    const { houses } = useDataContext();

    return (
        <div
            id="homeHousesSliderSection"
            className="home-real-estates-slider-subsection"
        >
            <div className="ltn__product-slider-area ltn__product-gutter">
                <div className="container-fluid container-lg">
                {
                    houses.length > 0
                    ?
                    <Slider
                        arrows={houses.length > 3}
                        dots={true}
                        infinite={houses.length > 3}
                        speed={300}
                        slidesToShow={3}
                        slidesToScroll={1}
                        prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                        nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                        responsive={[
                            {
                                breakpoint: 992,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 580,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                        className="row ltn__product-slider-item-four-active slick-arrow-1"
                    >
                    {
                        houses.slice(0, 6).map((house, index) => {
                            return (
                                // {/* ltn__product-item */}
                                <div key={index} className="col-12">
                                {
                                    getHousesSliderItem(house)
                                }
                                </div>
                                // {/* ltn__product-item */}
                            );
                        })
                    }
                    </Slider>
                    :
                    <div className="d-flex align-items-center justify-content-center py-5">
                        <div className="w-100 d-flex align-items-center justify-content-center alert alert-primary">
                            <p className="m-0">Aucune maison disponible pour le moment</p>
                        </div>
                    </div>
                }
                </div>
                {
                    houses.length > 0 &&
                    <div className="btn-wrapper text-center go-top">
                        <Link to="/maisons" className="btn theme-btn-1 btn-effect-1 text-uppercase">Voir tout</Link>
                    </div>
                }
            </div>
        </div>
    );
};

export default HousesSlider;

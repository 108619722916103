import React from "react";

import SubdivisionsSlider from "./lands/SubdivisionsSlider";
import UndividedPlotsSlider from "./lands/UndividedPlotsSlider";
import HousesSlider from "./houses/HousesSlider";
import ApartmentsSlider from "./apartments/ApartmentsSlider";

const RealEstatesSlider = () => {

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div id="homeLandsSliderSection">
            {/* <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7"> */}
            <div className="ltn__product-slider-area ltn__product-gutter pt-115-- pb-90">
                <div className="container-fluid container-lg">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Biens immobiliers</h6>
                                <h2 className="section-title">Nos différents biens immobiliers</h2>
                            </div>
                        </div>
                    </div>
                    <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
			            <div className="nav">
                            <a className="active show" data-bs-toggle="tab" href="#liton_tab_3_1">Lotissements</a>
                            <a data-bs-toggle="tab" href="#liton_tab_3_2">Parcelles</a>
                            <a data-bs-toggle="tab" href="#liton_tab_3_3">Maisons</a>
                            <a data-bs-toggle="tab" href="#liton_tab_3_4">Appartements</a>
			            </div>
			        </div>
			        <div className="tab-content">
                        <div className="tab-pane fade active show" id="liton_tab_3_1">
                            <div className="ltn__product-tab-content-inner">
                                <SubdivisionsSlider />
                            </div>
			            </div>
			            <div className="tab-pane fade" id="liton_tab_3_2">
                            <div className="ltn__apartments-tab-content-inner">
                                <UndividedPlotsSlider />
                            </div>
			            </div>
			            <div className="tab-pane fade" id="liton_tab_3_3">
                            <div className="ltn__apartments-tab-content-inner">
                                <HousesSlider />
                            </div>
			            </div>
			            <div className="tab-pane fade" id="liton_tab_3_4">
                            <div className="ltn__apartments-tab-content-inner">
                                <ApartmentsSlider />
                            </div>
			            </div>
			        </div>
                </div>
            </div>
        </div>
    );
};

export default RealEstatesSlider;

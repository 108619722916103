import React from 'react';
import { Link } from 'react-router-dom';

/**
 * 
 * @returns 
 */
const InteriorDesign = () => {

    const publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="apartments-plan-img">
                    <img src={`${publicUrl}assets/img/illustrations/modern-dining-room-with-elegant-interior-design-2024-10-23-22-33-11-utc-r-2.jpg`} alt="Illustration" />
                </div>
            </div>
        </div>
    );
};

export default InteriorDesign;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import parse from "html-react-parser";
import { useDataContext } from "../../../data/providers/DataProvider";
import { formatNumber } from "../../../utils/AssetHelpers";
import { getBadgeColorByStatus, getStatus } from "../../../utils/shared";
import Slider from "react-slick";
import { getHousesSliderItem } from "./houses/HousesSlider";
import { getApartmentsSliderItem } from "./apartments/ApartmentsSlider";
import { getPlotsSliderItem } from "./lands/PlotsSlider";

/**
 * 
 * @returns 
 */
export const getRealEstatesForRentSliderItem = (realEstate) => {
    switch (realEstate.real_estate.type) {
        case 'LAND':
            return getPlotsSliderItem(realEstate);
        case 'HOUSE':
            return getHousesSliderItem(realEstate);
        case 'APARTMENT':
            return getApartmentsSliderItem(realEstate);
        default:
            break;
    }
};

/**
 * 
 * @returns 
 */
const RealEstatesForRentSlider = () => {

    const { lands, houses, apartments } = useDataContext();

    const [realEstatesForRent, setRealEstatesForRent] = useState([]);

    useEffect(() => {
        const _realEstatesForRent = lands.filter((l) => l.real_estate.status === 'RENT').slice(0, 3);
        _realEstatesForRent.push(...houses.filter((h) => h.real_estate.status === 'RENT').slice(0, 3))
        _realEstatesForRent.push(...apartments.filter((a) => a.real_estate.status === 'RENT').slice(0, 3))
        setRealEstatesForRent(_realEstatesForRent);

    }, [apartments, houses, lands]);

    return (
        <div id="">
            {/* <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7"> */}
            <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 section-bg-1---">
                <div className="container-fluid container-lg">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">À louer</h6>
                                <h2 className="section-title">Nos biens à louer</h2>
                            </div>
                        </div>
                    </div>
                {
                    realEstatesForRent.length > 0
                    ?
                    <Slider
                        arrows={realEstatesForRent.length > 3}
                        dots={true}
                        infinite={realEstatesForRent.length > 3}
                        speed={300}
                        slidesToShow={3}
                        slidesToScroll={1}
                        prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                        nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                        responsive={[
                            {
                                breakpoint: 992,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 580,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                        className="row ltn__product-slider-item-four-active slick-arrow-1"
                    >
                    {
                        // realEstatesForSale.slice(0, 6).map((realEstate, index) => {
                        realEstatesForRent.map((realEstate, index) => {
                            return (
                                // {/* ltn__product-item */}
                                <div key={index} className="col-12">
                                {
                                    // console.log(realEstate)
                                    getRealEstatesForRentSliderItem(realEstate)
                                }
                                </div>
                                // {/* ltn__product-item */}
                            );
                        })
                    }
                    </Slider>
                    :
                    <div className="d-flex align-items-center justify-content-center py-5">
                        <div className="w-100 d-flex align-items-center justify-content-center alert alert-primary">
                            <p className="m-0">Aucun bien à louer disponible pour le moment</p>
                        </div>
                    </div>
                }
                </div>
                {
                    realEstatesForRent.length > 0 &&
                    <div className="btn-wrapper text-center go-top">
                        <Link to="/maisons" className="btn theme-btn-1 btn-effect-1 text-uppercase">Voir tout</Link>
                    </div>
                }
            </div>
        </div>
    );
};

export default RealEstatesForRentSlider;

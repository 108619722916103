import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

/**
 * 
 * @returns 
 */
const Services = () => {

    const publicUrl = process.env.PUBLIC_URL + '/';
    // const customClass = props.customClass ? props.customClass : '';

    const services = [
        {
            icon: `${publicUrl}assets/img/icons/icon-img/21.png`,
            title: 'Vente de maisons familiales',
            descrption: 'Spécialisation dans la vente de maisons répondant à divers besoins résidentiels, incluant les maisons familiales, individuelles et en lotissement.',
            url: '/maisons',
            linkText: 'Trouver une maison'
        },
        {
            icon: `${publicUrl}assets/img/icons/icon-img/22.png`,
            title: 'Transactions de terrains nus pour projets immobiliers',
            descrption: 'Expertise dans la vente de terrains nus destinés à la construction, que ce soit pour des projets résidentiels, commerciaux ou industriels.',
            url: '/terrains/lotissements',
            linkText: 'Trouver un terrain nu'
        },
        {
            icon: `${publicUrl}assets/img/icons/icon-img/23.png`,
            title: 'Appartements modernes et pratiques',
            descrption: "Spécialisation dans la vente d'appartements modernes, allant des studios aux grands appartements, en mettant l'accent sur les caractéristiques pratiques et contemporaines.",
            url: '/appartements',
            linkText: 'Trouver un appartement'
        },
    ];
    return (
        <div className={`ltn__feature-area section-bg-1 pt-120 pb-120 mb-120---`} >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area ltn__section-title-2--- text-center">
                            <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Nos Services</h6>
                            <h2 className="section-title">Nos principales spécialités</h2>
                        </div>
                    </div>
                </div>
                <div className="row ltn__custom-gutter--- justify-content-center go-top">
                {
                    services.map((service, index) => {
                        return (
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1">
                                    <div className="ltn__feature-icon">
                                        <img
                                            src={service.icon}
                                            alt="Illustration"
                                        />
                                    </div>
                                    <div className="ltn__feature-info">
                                        <h3>
                                            <span>{service.title}</span>
                                        </h3>
                                        <p>{service.descrption}</p>
                                        <Link
                                            className="ltn__service-btn"
                                            to={service.url}
                                        >
                                            <span>{service.linkText}</span>
                                            {" "}
                                            <i className="flaticon-right-arrow" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
        </div>
    );
};

export default Services;

import { createContext } from "react";

/**
 * Create the data context
*/
const ResearchDataContext = createContext({
    landLocality: null,
    landMunicipality: null,
    landDepartment: null,
    landRegion: null,
    serviced: null,
    landPriceRange: null,
    landSuperficiesRange: null,

    subdivisionLocality: null,
    subdivisionMunicipality: null,
    subdivisionDepartment: null,
    subdivisionRegion: null,
    subdivisionSuperficiesRange: null,

    houseCategory: null,
    houseLocality: null,
    houseMunicipality: null,
    houseDepartment: null,
    houseRegion: null,
    houseFloorsNumber: null,
    houseRoomsNumber: null,
    houseToiletsNumber: null,
    housePriceRange: null,
    houseSuperficiesRange: null,

    apartmentCategory: null,
    apartmentLocality: null,
    apartmentMunicipality: null,
    apartmentDepartment: null,
    apartmentRegion: null,
    apartmentRoomsNumber: null,
    apartmentToiletsNumber: null,
    apartmentLevel: null,
    apartmentPriceRange: null,
    apartmentSuperficiesRange: null,

    setLandLocality: () => {/* */},
    setLandMunicipality: () => {/* */},
    setLandDepartment: () => {/* */},
    setLandRegion: () => {/* */},
    setServiced: () => {/* */},
    setLandPriceRange: () => {/* */},
    setLandSuperficiesRange: () => {/* */},

    setSubdivisionLocality: () => {/* */},
    setSubdivisionMunicipality: () => {/* */},
    setSubdivisionDepartment: () => {/* */},
    setSubdivisionRegion: () => {/* */},
    setSubdivisionSuperficiesRange: () => {/* */},

    setHouseCategory: () => {/* */},
    setHouseLocality: () => {/* */},
    setHouseMunicipality: () => {/* */},
    setHouseDepartment: () => {/* */},
    setHouseRegion: () => {/* */},
    setHouseFloorsNumber: () => {/* */},
    setHouseRoomsNumber: () => {/* */},
    setHouseToiletsNumber: () => {/* */},
    setHousePriceRange: () => {/* */},
    setHouseSuperficiesRange: () => {/* */},

    setApartmentCategory: () => {/* */},
    setApartmentLocality: () => {/* */},
    setApartmentMunicipality: () => {/* */},
    setApartmentDepartment: () => {/* */},
    setApartmentRegion: () => {/* */},
    setApartmentRoomsNumber: () => {/* */},
    setApartmentToiletsNumber: () => {/* */},
    setApartmentLevel: () => {/* */},
    setApartmentPriceRange: () => {/* */},
    setApartmentSuperficiesRange: () => {/* */},
});

export default ResearchDataContext;

import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import Swal from 'sweetalert2';
import { useResearchDataContext } from "../providers/ResearchDataProvider";
import Select from 'react-select';

import {
    customSelectClearIndicator,
    customSelectControl,
    customSelectDropdownIndicator,
    customSelectMenu,
    customSelectMenuList,
    customSelectOption,
    customSelectPlaceholder,
    customSelectStyles,
    customSelectValueContainer
} from "../../global-components/select-customizer";
import { useDataContext } from "../../../data/providers/DataProvider";
import RangeSlider from "../../global-components/ui/RangeSlider";

const ApartmentSearch = () => {

    const { regions, municipalities, apartmentPriceRanges} = useDataContext();
    const researchData = useResearchDataContext();

    const levelSelectRef = useRef(null);
    const roomsNumberSelectRef = useRef(null);
    const toiletsNumberSelectRef = useRef(null);
    const priceRangeInputRef = useRef(null);
    const regionsSelectRef = useRef(null);
    const municipalitiesSelectRef = useRef(null);
    const [municipalitiesByRegion, setMunicipalitiesByRegion] = useState([]);
    const [disableMunicipalitiesSelect, setDisableMunicipalitiesSelect] = useState(true);

    const navigate = useNavigate();

    /**
     * 
     * @param region 
     */
    const changeApartmentRegion = (region) => {
        researchData.setApartmentRegion(region);

        if(region === null || region === undefined){
            setMunicipalitiesByRegion([]);
            municipalitiesSelectRef.current.clearValue();
            setDisableMunicipalitiesSelect(true);
            // setIsValidComplainantRegion(false);
        }
        else{
            setMunicipalitiesByRegion(municipalities.filter((municipality) => municipality.region_id === region.id));
            setDisableMunicipalitiesSelect(false);

            if(researchData.apartmentMunicipality !== null &&
                researchData.apartmentMunicipality !== null &&
                researchData.apartmentMunicipality?.region_id !== region.id){
                    municipalitiesSelectRef.current.clearValue();
            }
            // setIsValidComplainantRegion(true);
        }
    };

    const searchApartment = () => {

        let errorMesg = '';

        // if(!researchData.apartmentCategory){
        //     errorMesg = 'Veuillez choisir une catégorie';
        // }
        if(!researchData.apartmentSuperficiesRange){
            errorMesg = 'Veuillez choisir une superficie';
        }
        else if(!researchData.apartmentRegion){
            errorMesg = 'Veuillez choisir une région';
        }
        else if(!researchData.apartmentMunicipality){
            errorMesg = 'Veuillez choisir une commune';
        }
        else if(!researchData.apartmentLevel){
            errorMesg = "Veuillez choisir le niveau";
        }
        else if(!researchData.apartmentRoomsNumber){
            errorMesg = 'Veuillez choisir le nombre de chambres';
        }
        else if(!researchData.apartmentToiletsNumber){
            errorMesg = 'Veuillez choisir le nombre de toilettes';
        }
        if(errorMesg.length > 0){
            Swal.fire({
                text: errorMesg,
                icon: 'warning',
                iconColor: '#b02127',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#b02127', 
                confirmButtonText: "D'accord !"
            });
        }
        else{
            navigate("/appartements");
        }
    };

    return (
        <div className="car-dealer-form-inner">
            <form
                action="#"
                className="ltn__car-dealer-form-box row"
            >
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-12 mb-0">
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-4 h-100" style={{paddingBottom: '30px'}}>
                        <div className="">
                            <label className="fw-bold">Catégorie:</label>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-sm-4">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="toRent"
                                    value="RENT"
                                    checked={researchData.apartmentCategory === 'RENT'}
                                    onChange={(e) => researchData.setApartmentCategory('RENT')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="toRENT"
                                >
                                    À louer
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="toSale"
                                    value="SALE"
                                    checked={researchData.apartmentCategory === 'SALE'}
                                    onChange={(e) => researchData.setApartmentCategory('SALE')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="notServiced"
                                >
                                    À vendre
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="status"
                                    id="toRentAndtoSale"
                                    value=""
                                    checked={researchData.apartmentCategory === null}
                                    onChange={(e) => researchData.setApartmentCategory(null)}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="servicedAndNotServiced"
                                >
                                    Tout
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-md-6 col-lg-4 mb-0">
                    <div className="input-item input-item-name ltn__custom-icon">
                        <input
                            type="text"
                            name="superficies"
                            placeholder="Superficie"
                            className="personalized-input"
                            onInput={(e) => researchData.setApartmentSuperficiesRange(e.target.value)}
                        />
                    </div>
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-md-6 col-lg-4 mb-md-0">
                    <Select
                        ref={regionsSelectRef}
                        components={{
                            Control: customSelectControl,
                            ClearIndicator: customSelectClearIndicator,
                            DropdownIndicator: customSelectDropdownIndicator,
                            Menu: customSelectMenu,
                            MenuList: customSelectMenuList,
                            Option: customSelectOption,
                            Placeholder: customSelectPlaceholder,
                            ValueContainer: customSelectValueContainer
                        }}
                        isClearable={true}
                        isSearchable={true}
                        menuPlacement='auto'
                        // menuPortalTarget={document.body}
                        name='region'
                        options={regions}
                        getOptionValue={(option) => option.code}
                        getOptionLabel={(option) => option.name}
                        placeholder='Région'
                        styles={customSelectStyles}
                        onChange={changeApartmentRegion}
                    />
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-md-6 col-lg-4 mb-md-0">
                    <Select
                        ref={municipalitiesSelectRef}
                        components={{
                            Control: customSelectControl,
                            ClearIndicator: customSelectClearIndicator,
                            DropdownIndicator: customSelectDropdownIndicator,
                            Menu: customSelectMenu,
                            MenuList: customSelectMenuList,
                            Option: customSelectOption,
                            Placeholder: customSelectPlaceholder,
                            ValueContainer: customSelectValueContainer
                        }}
                        isClearable={true}
                        isSearchable={true}
                        isDisabled={disableMunicipalitiesSelect}
                        menuPlacement='auto'
                        // menuPortalTarget={document.body}
                        name='apartment_municipality'
                        options={municipalitiesByRegion}
                        getOptionValue={(option) => option.code}
                        getOptionLabel={(option) => option.name}
                        placeholder='Commune'
                        styles={customSelectStyles}
                        onChange={researchData.setApartmentMunicipality}
                    />
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-md-6 col-lg-4">
                    <Select
                        ref={levelSelectRef}
                        components={{
                            Control: customSelectControl,
                            ClearIndicator: customSelectClearIndicator,
                            DropdownIndicator: customSelectDropdownIndicator,
                            Menu: customSelectMenu,
                            MenuList: customSelectMenuList,
                            Option: customSelectOption,
                            Placeholder: customSelectPlaceholder,
                            ValueContainer: customSelectValueContainer
                        }}
                        isClearable={true}
                        isSearchable={true}
                        menuPlacement='auto'
                        // menuPortalTarget={document.body}
                        name='level'
                        options={[
                            {wording: "1er étage", value: "1"},
                            {wording: "2ème étage", value: "2"},
                            {wording: "3ème étage", value: "3"},
                            {wording: "4ème étage", value: "4"},
                            {wording: "5ème étage", value: "5"},
                            {wording: "6ème étage", value: "6"},
                            {wording: "7ème étage et plus", value: "7+"}
                        ]}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.wording}
                        placeholder="Niveau"
                        styles={customSelectStyles}
                        onChange={researchData.setApartmentLevel}
                    />
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-md-6 col-lg-4">
                    <Select
                        ref={roomsNumberSelectRef}
                        components={{
                            Control: customSelectControl,
                            ClearIndicator: customSelectClearIndicator,
                            DropdownIndicator: customSelectDropdownIndicator,
                            Menu: customSelectMenu,
                            MenuList: customSelectMenuList,
                            Option: customSelectOption,
                            Placeholder: customSelectPlaceholder,
                            ValueContainer: customSelectValueContainer
                        }}
                        isClearable={true}
                        isSearchable={true}
                        menuPlacement='auto'
                        // menuPortalTarget={document.body}
                        name='apartment_rooms_number'
                        options={[
                            {wording: "1", value: "1"},
                            {wording: "2", value: "2"},
                            {wording: "3", value: "3"},
                            {wording: "4 et plus", value: "4+"}
                        ]}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.wording}
                        placeholder='Nombre de chambres'
                        styles={customSelectStyles}
                        onChange={researchData.setApartmentRoomsNumber}
                    />
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-md-6 col-lg-4">
                    <Select
                        ref={toiletsNumberSelectRef}
                        components={{
                            Control: customSelectControl,
                            ClearIndicator: customSelectClearIndicator,
                            DropdownIndicator: customSelectDropdownIndicator,
                            Menu: customSelectMenu,
                            MenuList: customSelectMenuList,
                            Option: customSelectOption,
                            Placeholder: customSelectPlaceholder,
                            ValueContainer: customSelectValueContainer
                        }}
                        isClearable={true}
                        isSearchable={true}
                        menuPlacement='auto'
                        // menuPortalTarget={document.body}
                        name='apartment_toilets_number'
                        options={[
                            {wording: "1", value: "1"},
                            {wording: "2", value: "2"},
                            {wording: "3 et plus", value: "3+"}
                        ]}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.wording}
                        placeholder='Nombre de toilettes'
                        styles={customSelectStyles}
                        onChange={researchData.setApartmentToiletsNumber}
                    />
                </div>
                <div className="car-price-filter-range col-lg-12">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9 col-xl-8">
                            <div className="price_filter">
                                <label className="mb-3">Fourchette de prix:{" "}</label>
                                {/* <div className="price_slider_amount d-flex flex-column flex-ms-row gap-2">
                                    <label className="mb-4">Fourchette de prix:{" "}</label>
                                    <input
                                        type="text"
                                        className="amount mb-4"
                                        name="price-range-to-display"
                                        placeholder="Fourchette de prix"
                                    />
                                    <input
                                        type="hidden"
                                        className="price-range"
                                        name="price-range"
                                        placeholder=""
                                        ref={priceRangeInputRef}
                                    />
                                </div>
                                <div className="slider-range" /> */}
                                <div className="position-relative">
                                {
                                    apartmentPriceRanges &&
                                    <RangeSlider
                                        // min={125000}
                                        // max={15200055}
                                        min={apartmentPriceRanges.min_price}
                                        max={apartmentPriceRanges.max_price}
                                        onChange={({ min, max }) => {
                                            // console.log(`min = ${min}, max = ${max}`)
                                            researchData.setApartmentPriceRange(`${min}-${max}`);
                                        }}
                                        // leftSliderCurrentValue={priceRangeLimits ? parseInt(priceRangeLimits[0]) : 125000}
                                        // rightSliderCurrentValue={priceRangeLimits ? parseInt(priceRangeLimits[1]) : 4500000}
                                    />
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrapper text-center go-top">
                        <button
                            type="button"
                            className="btn theme-btn-1 btn-effect-1 text-uppercase"
                            onClick={searchApartment}
                        >
                            Rechercher
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ApartmentSearch;

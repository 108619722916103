import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import parse from "html-react-parser";
import { useDataContext } from "../../../../data/providers/DataProvider";
import { formatNumber } from "../../../../utils/AssetHelpers";
import { getBadgeColorByStatus, getStatus } from "../../../../utils/shared";
import Slider from "react-slick";

/**
 * 
 * @param {*} land 
 */
export const getPlotsSliderItem = (land) => {

    const publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div className="ltn__product-item ltn__product-item-4 text-center---">
            <div className="product-img go-top">
                <Link to={`/terrains/parcelles-non-loties/${land.id}`}>
                    <img
                        // src={publicUrl + "assets/img/product-3/1.jpg"}
                        src={`${publicUrl}assets/img/lands/image-terrain-${land.real_estate.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                        // src={land.real_estate.images[0].data}
                        alt="Illustration"
                    />
                </Link>
                <div className="product-badge">
                    <ul>
                        <li className={`sale-badge ${getBadgeColorByStatus(land.real_estate)}`}>{getStatus(land.real_estate)}</li>
                    </ul>
                </div>
                {/* <div className="real-estate-agent">
                    <div className="agent-img">
                        <a href="#/team-details">
                            <img src="/tf/react/quarter-preview/quarter/assets/img/blog/author.jpg" alt="#" />
                        </a>
                    </div>
                </div> */}
                <div className="product-img-location-gallery px-3">
                    <div 
                        className="product-img-location d-flex align-items-center"
                        title={`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                    >
                        <p
                            className="m-0 text-white text-truncate cursor-default"
                            title={`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                        >
                        <small>
                            <i className="flaticon-pin" />{" "}
                            {`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                        </small>
                        </p>
                    </div>
                {
                    land.real_estate.belongs_to_alphaimmo &&
                    <div className="real-estate-agent">
                        <div className="agent-img bg-white rounded-circle p-1 m-0">
                            <a href="/alfaimmo">
                                <img
                                    src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                    alt="Mascotte ALFAIMMO"
                                    width={32}
                                    title="ALFAIMMO"
                                />
                            </a>
                        </div>
                    </div>
                }
                </div>
            </div>
            <div className="product-info">
                <div className="product-price fs-6">
                    <small>
                        <span>{formatNumber(land.real_estate.price)} <label>FCFA</label></span>
                    {
                        land.real_estate.monthly_price &&
                        <span>{" - "}{formatNumber(land.real_estate.monthly_price)} <label>FCFA</label><label>/Month</label></span>
                    }
                    </small>
                </div>
                <h3 className="product-title go-top fs-6">
                    <Link to={`/terrains/parcelles-non-loties/${land.id}`}>{`Terrain nu - lot n° ${land.number}`}</Link>
                </h3>
                <div className="product-description">
                    <p className="clamped-text clamped-text-1">{land.real_estate.description}</p>
                </div>
                <ul className="ltn__list-item-2 ltn__list-item-2-before personalized row">
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Supercifie:</span>
                        <span>{parseInt(land.real_estate.superficies)} m<sup>2</sup></span>
                    </li>
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Position:</span>{" "}
                        <span>{land.position === 'CORNER' ? "Angle" : "Simple"}</span>
                    </li>
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Catégorie:</span>{" "}
                        <span>{land.category === 'TF' ? "Titre foncier" : "Bail"}</span>
                    </li>
                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                        <span>Viabilisé:</span>
                        <span>{land.serviced === 'YES' ? 'Oui' : 'Non'}</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

/**
 * 
 * @returns 
 */
const PlotsSlider = () => {

    const { lands } = useDataContext();

    return (
        <div
            id="homeUndividedPlotsSliderSection"
            className="home-real-estates-slider-subsection home-land-slider-subsection"
        >
            <div className="ltn__product-slider-area ltn__product-gutter">
                <div className="container-fluid container-lg">
                {
                    lands.length > 0
                    ?
                    <Slider
                        arrows={lands.length > 3}
                        dots={true}
                        infinite={lands.length > 3}
                        speed={300}
                        slidesToShow={3}
                        slidesToScroll={1}
                        prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                        nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                        responsive={[
                            {
                                breakpoint: 992,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 580,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 480,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                        className="row ltn__product-slider-item-four-active slick-arrow-1"
                    >
                    {
                        lands.slice(0, 6).map((land, index) => {
                            return (
                                <div
                                    key={index}
                                    className="col-12"
                                >
                                {
                                    getPlotsSliderItem(land)
                                }
                                </div>
                            );
                        })
                    }
                    </Slider>
                    :
                    <div className="d-flex align-items-center justify-content-center py-5">
                        <div className="w-100 d-flex align-items-center justify-content-center alert alert-primary">
                            <p className="m-0">Aucun terrain disponible pour le moment</p>
                        </div>
                    </div>
                }
                </div>
                <div className="btn-wrapper text-center go-top">
                    <Link to="/terrains/parcelles-non-loties" className="btn theme-btn-1 btn-effect-1 text-uppercase">Voir tout</Link>
                </div>
            </div>
        </div>
    );
};

export default PlotsSlider;

import React from 'react';
import { Link } from 'react-router-dom';

import SocialsNetwork from '../section-components/SocialsNetwork';

/**
 * 
 * @returns 
 */
const TopBar = () => {
	
    const publicUrl = process.env.PUBLIC_URL+'/';

    return (
        <div className="ltn__header-top-area py-1 top-area-color-whitee">
            <div className="container">
                <div className="row">
                    <div className="col-md-7">
                        <div className="ltn__top-bar-menu text-start d-flex align-items-center justify-content-center justify-content-md-start">
                            <ul className="d-flex flex-column flex-md-row align-items-start justify-content-center">
                                <li>
                                    <a
                                        href="mailto:commercial@alfaimmobilier.com"
                                        className="d-inline-flex align-items-center"
                                    >
                                        <i className="icon-mail d-blockk" />{" "}
                                        <span>commercial@alfaimmobilier.com</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="tel:+221778076565"
                                        className="d-inline-flex align-items-center"
                                    >
                                        <i className="icon-phone-call" />{" "}
                                        <span>(+221) 77 807 65 65</span>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="locations.html" className="d-inline-flex">
                                        <i className="icon-placeholder pt-1" />{" "}
                                        <span>Grand Mbao Extension villa n°1329 en face stade de Mbao</span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="top-bar-right text-end">
                            <div className="ltn__top-bar-menu">
                                <ul>
                                    <li>
                                        {/* ltn__language-menu */}
                                        <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                                            {/* <ul>
                                                <li>
                                                    <Link
                                                        to="#"
                                                        className="dropdown-toggle"
                                                    >
                                                        <span className="active-currency">Français</span>
                                                    </Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="#">Arabic</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Bengali</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Chinese</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">English</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Français</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Hindi</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                        </div>
                                        {/* ltn__language-menu */}
                                    </li>
                                    <li>
                                        <SocialsNetwork />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;

import React, { useEffect, useState } from "react";

import { formatPhoneNumber } from "../../../utils/AssetHelpers";
import { FormFeedback } from "reactstrap";
import { useLoaderSpinnerContext } from "../../global-components/providers/LoaderSpinnerProvider";
import api from "../../../services/api";
import useGoTo from "../../global-components/hooks/useGoTo";
import Swal from "sweetalert2";

/**
 * 
 * @returns 
 */
const Form = () => {

    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const [isValidFullName, setIsValidFullName] = useState(false);
    const [isTouchedForFullName, setIsTouchedForFullName] = useState(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
    const [isTouchedForPhoneNumber, setIsTouchedForPhoneNumber] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isTouchedForEmail, setIsTouchedForEmail] = useState(false);
    const [isValidSubject, setIsValidSubject] = useState(false);
    const [isTouchedForSubject, setIsTouchedForSubject] = useState(false);
    const [isValidMessage, setIsValidMessage] = useState(false);
    const [isTouchedForMessage, setIsTouchedForMessage] = useState(false);

    const [reset, setReset] = useState(false);

    useEffect(() => {
        if(reset){
            setFullName('');
            setIsTouchedForFullName(false);
            setPhoneNumber('');
            setIsTouchedForPhoneNumber(false);
            setEmail('');
            setIsTouchedForEmail(false);
            setSubject('');
            setIsTouchedForSubject(false);
            setMessage('');
            setIsTouchedForMessage(false);
            setReset(false);
        }
    },[reset]);

    /**
     * 
     * @returns 
     */
    const isValidData = () => {
        return (
            isValidFullName &&
            isValidPhoneNumber &&
            isValidEmail &&
            isValidSubject &&
            isValidMessage
        );
    };

    /**
     * 
     * @returns 
     */
    const validateData = () => {
        if(!isValidData()){
            setIsTouchedForFullName(!isValidFullName);
            setIsTouchedForPhoneNumber(!isValidPhoneNumber);
            setIsTouchedForEmail(!isValidEmail);
            setIsTouchedForSubject(!isValidSubject);
            setIsTouchedForMessage(!isValidMessage)
            return false;
        }
        return true;
    };

    /**
     * 
     * @param name 
     */
    const _setFullName = (name) => {
        setFullName(name);
        setIsValidFullName(name.length > 0);
    };

    /**
     * 
     * @param _phoneNumber 
     */
    const _setPhoneNumber = (_phoneNumber) => {
        setPhoneNumber(formatPhoneNumber(_phoneNumber));
        setIsValidPhoneNumber(_phoneNumber.length > 0 &&
            _phoneNumber.match(/(^3[3]|^7[5-80])[ ]?[0-9]{3}([ ]?[0-9]{2}){2}$/) !== null);
    };

    /**
     * 
     * @param _email 
     */
    const _setEmail = (_email) => {
        setEmail(_email);
        setIsValidEmail(_email.length > 0 && _email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null);
    };

    /**
     * 
     * @param _subject 
     */
    const _setSubject = (_subject) => {
        setSubject(_subject);
        setIsValidSubject(_subject.length > 0);
    };

    /**
     * 
     * @param _message 
     */
    const _setMessage = (_message) => {
        setMessage(_message);
        setIsValidMessage(_message.length > 0);
    };

    const goTo = useGoTo();
    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();

    const sendForm = () => {

        if(!validateData()) {
            // showToast(`${t('Veuillez remplir tous les champs')} !`, 'warning', { autoClose: 2000 });
            // showToast('Veuillez remplir tous les champs !', 'warning', { autoClose: 2000 });
            return;
        }
        setShowLoaderSpinner(true);

        const data = {
            "full_name": fullName,
            "phone_number": phoneNumber,
            "email": email,
            "subject": subject,
            "message": message,
        };
        api.post('/register-quote-request', data,
            {
                headers: {
                    // Authorization : `Bearer ${token}`,
                    // 'Content-Type': 'multipart/form-data'
                }
            }
        ).then((res) => {
            if(res.status === 200 && res.data.message){
                setTimeout(() => {
                    Swal.fire({
                        text: res.data.message,
                        icon: 'success',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonColor: '#7bcb4d', 
                        confirmButtonText: "D'accord !"
                    }).then((r) => {
                        setShowLoaderSpinner(true);
                        // goTo('/maisons');
                        setTimeout(() => {
                            setShowLoaderSpinner(false);
                        }, 1400);
                    });
                    setTimeout(() => {
                        setShowLoaderSpinner(false);
                    }, 400);
                }, 600);
            }
            else{
                setShowLoaderSpinner(false);
                // showToast(res.data.message ? t(res.data.message) : `${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            }
        }).catch((error) => {
            Swal.fire({
                text: error.response.data.message ? error.response.data.message : "Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur",
                icon: 'error',
                iconColor: '#b02127',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#b02127', 
                confirmButtonText: "D'accord !"
            });
            setShowLoaderSpinner(false);
            // showToast(error.response.data.message ? t(error.response.data.message) : `${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.dir(error);
        });
    };

    return (
        <div className="ltn__form-box contact-form-box box-shadow white-bg px-4 py-4">
            <h4 className="title-2">Demander un devis</h4>
            <form id="quoteForm">
                <div className="row">
                    <div className="col-12">
                        <div className={`form-group-wrapper mb-1 ${!isValidFullName && isTouchedForFullName ? 'is-invalid' : ''}`}>
                            <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                    type="text"
                                    name="full_name"
                                    placeholder="Prénom et nom"
                                    className="mb-0"
                                    defaultValue=""
                                    onBlur={() => setIsTouchedForFullName(true)}
                                    onInput={(e) => _setFullName(e.currentTarget.value)}
                                />
                            </div>
                            <FormFeedback invalid='true' className="ps-1">{'Veuillez renseigner ce champ'} !</FormFeedback>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`form-group-wrapper mb-1 ${!isValidPhoneNumber && isTouchedForPhoneNumber ? 'is-invalid' : ''}`}>
                            <div className="input-item input-item-phone ltn__custom-icon">
                                <input
                                    type="tel"
                                    name="phone_number"
                                    placeholder="Numéro de téléphone"
                                    className="mb-0"
                                    defaultValue=""
                                    onBlur={() => setIsTouchedForPhoneNumber(true)}
                                    onInput={(e) => _setPhoneNumber(e.currentTarget.value)}
                                />
                            </div>
                            <FormFeedback invalid='true' className="ps-1">{!phoneNumber.length ? 'Veuillez renseigner le numéro de téléphone' : 'Numéro de téléphone invalide'} !</FormFeedback>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={`form-group-wrapper mb-1 ${!isValidEmail && isTouchedForEmail ? 'is-invalid' : ''}`}>
                            <div className="input-item input-item-email ltn__custom-icon">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Adress e-mail"
                                    className="mb-0"
                                    defaultValue=""
                                    onBlur={() => setIsTouchedForEmail(true)}
                                    onInput={(e) => _setEmail(e.currentTarget.value)}
                                />
                            </div>
                            <FormFeedback invalid='true' className="ps-1">{!email.length ? 'Veuillez renseigner ce champ' : 'Adresse e-mail invalide'} !</FormFeedback>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={`form-group-wrapper mb-1 ${!isValidSubject && isTouchedForSubject ? 'is-invalid' : ''}`}>
                            <div className="input-item input-item-subject ltn__custom-icon">
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder="Objet...."
                                    className="mb-0"
                                    defaultValue=""
                                    onBlur={() => setIsTouchedForSubject(true)}
                                    onInput={(e) => _setSubject(e.currentTarget.value)}
                                />
                            </div>
                            <FormFeedback invalid='true' className="ps-1">{'Veuillez renseigner ce champ'} !</FormFeedback>
                        </div>
                    </div>
                </div>
                <div className="input-item input-item-textarea ltn__custom-icon">
                    <div className={`form-group-wrapper mb-1 ${!isValidMessage && isTouchedForMessage ? 'is-invalid' : ''}`}>
                        <div className="input-item input-item-textarea ltn__custom-icon">
                            <textarea
                                name="message"
                                rows={6}
                                placeholder="Message"
                                // defaultValue={""}
                                className="h-100 mb-0"
                                defaultValue=""
                                onBlur={() => setIsTouchedForMessage(true)}
                                onInput={(e) => _setMessage(e.currentTarget.value)}
                            />
                        </div>
                        <FormFeedback invalid='true' className="ps-1 mt-0">{'Veuillez renseigner ce champ'} !</FormFeedback>
                    </div>
                </div>
                {/* <p>
                    <label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label>
                </p> */}
                <div className="btn-wrapper my-0">
                    <button
                        type="button"
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                        onClick={sendForm}
                    >
                        Soumettre
                    </button>
                </div>
                {/* <p className="form-message mb-0 mt-20" /> */}
            </form>
        </div>
    );
};

export default Form;

import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
/**
 * 
 * @returns 
 */
const SocialsNetwork = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'

    return (
        <div className="ltn__social-media">
			<ul>
				<li>
                    <a
                        href="https://www.facebook.com/share/13nfEKaQ7w/?mibextid=LQQJ4d"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Facebook"
                    >
                        {/* <i className="fab fa-facebook-f" /> */}
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                </li>
				{/* <li><a href="https://www.twitter.com/tuna-theme" title="Twitter"><i className="fab fa-twitter" /></a></li> */}
				<li>
                    <a
                        href="https://www.instagram.com/alfa_immo/profilecard/?igsh=MWVlNXE0Ym41anM3aQ=="
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Instagram"
                    >
                        {/* <i className="fab fa-instagram" /> */}
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </li>
				<li>
                    <a
                        href="https://www.tiktok.com/@alfa.immobilier.s?_t=8ruWj9fwSal&_r=1"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="TikTok"
                    >
                        {/* <i className="fab fa-tiktok"></i> */}
                        <FontAwesomeIcon icon={faTiktok} />
                    </a>
                </li>
				{/* <li><a href="https://www.dribble.com/tuna-theme" title="Dribbble"><i className="fab fa-dribbble" /></a></li> */}
			</ul>
		</div>
    );
}

export default SocialsNetwork;

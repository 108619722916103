import React from "react";

import L from "leaflet";
import { Circle, ImageOverlay, LayersControl, MapContainer, Marker, Popup, TileLayer, FeatureGroup, LayerGroup } from "react-leaflet";
import { GeoJSON } from 'react-leaflet/GeoJSON'
import { formatNumber } from "../../../utils/AssetHelpers";
import { useDataContext } from "../../../data/providers/DataProvider";

import 'azure-maps-control/dist/atlas.min.css';

const { BaseLayer } = LayersControl;

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const SubdivisionMap = ({subdivision, googleEarthUrl, circleRadius, zoom = 12}) => {

    // const {lands} = useDataContext();

    const customIcon = new L.Icon({
        iconUrl: require("./location.svg").default,
        iconSize: new L.Point(40, 47)
    });

    return (
        <MapContainer
            // className=""
            // center={[subdivision.latitude, subdivision.longitude]}
            // zoom={zoom}
            // attributionControl={true}
            // inertia={true}
            // scrollWheelZoom={false}

            className=""
            center={[subdivision.latitude, subdivision.longitude]}
            // zoom={zoom}
            zoom={12}
            scrollWheelZoom={false}
        >
            {/* <LayersControl>
                <BaseLayer name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                </BaseLayer>
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid" checked>
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
            </LayersControl> */}
            {/* [{
    "type": "LineString",
    "coordinates": [[-100, 40], [-105, 45], [-110, 55]]
}, {
    "type": "LineString",
    "coordinates": [[-105, 40], [-110, 45], [-115, 55]]
}] */}
            {/* <GeoJSON data={{
                type: 'LineString',
                bbox: [
                    [subdivision.longitude, subdivision.latitude],
                    [subdivision.longitude, subdivision.latitude],
                    [subdivision.longitude, subdivision.latitude]
                ],
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [[
                        [[subdivision.longitude, subdivision.latitude],
                        [subdivision.longitude, subdivision.latitude],
                        [subdivision.longitude, subdivision.latitude]]
                    ]]
                }
            }}
            style={{
                "color": "#ff7800",
                "weight": 5,
                "opacity": 0.65
            }}
            /> */}
            <LayersControl>
                <BaseLayer name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
                {/* <BaseLayer name="Stadia Alidade Smooth">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}"
                        attribution="&copy; <a href=&quot;https://www.stadiamaps.com/&quot; target=&quot;_blank&quot;>Stadia Maps</a> &copy; <a href=&quot;https://openmaptiles.org/&quot; target=&quot;_blank&quot;>OpenMapTiles</a> &copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia OSM Bright">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Outdoors">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Alidade Satellite">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="jpg"
                        language='fr-FR'
                    />
                </BaseLayer> */}
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Azure Road">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="Azure Hybrid" checked>
                    <LayerGroup>
                        {/* <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.darkgrey&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='en-US'
                            maxZoom={20}
                        /> */}
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="Azure Satellite">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="ArcGis Hybrid">
                    <LayerGroup>
                        <TileLayer
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                            attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="ArcGis Satellite">
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                        // attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
            </LayersControl>
            {/* <Circle
                center={{
                    lat:subdivision.latitude,
                    lng:subdivision.longitude
                }}
                color='red'
                fillColor='#f03'
                fillOpacity={0.0}
                // radius={circleRadius ? circleRadius : 1500}
                radius={(parseInt(subdivision.superficies) < 100) ? parseInt(subdivision.superficies)*10 : parseInt(subdivision.superficies)*2}
                // radius={300}
            > */}
                {/* <ImageOverlay
                // url="https://maps.lib.utexas.edu/maps/historical/newark_nj_1922.jpg"
                url={`${process.env.PUBLIC_URL}/assets/img/lands/${subdivision && subdivision.municipality_id === 466 ? 'LOTISSEMENT-KOUNOUNE-14-PARCELLES-2' : 'LOTISSEMENT-POUT-KHININE-50-PARCELLES'}.png`}
                errorOverlayUrl="https://cdn-icons-png.flaticon.com/512/110/110686.png"
                bounds={[[subdivision.latitude, subdivision.longitude], [subdivision.latitude - 0.05, subdivision.longitude - 0.05]]}
                opacity={1}
                // className="section-bg-1"
            /> */}
            {/* </Circle> */}
                <Marker
                    position={[subdivision.latitude, subdivision.longitude]}
                    icon={customIcon}
                >
                    <Popup>
                        <div>
                            <h6>{subdivision.wording}</h6>
                            {/* <p>{land.real_estate.description}</p> */}
                            <div>
                                <label>Nombre de parcelles:</label>
                                <span>
                                    {formatNumber(subdivision.lands_count)}
                                </span>
                            </div>
                            <div className="btn-wrapper text-center">
                                <a
                                    // href="https://earth.google.com/static/multi-threaded/versions/10.65.1.2/index.html?#data=MkEKPwo9CiExbzUweDZSV2RCbnA3NS1PbUlkQUtXZ2VxU0Z3UjYzTjUSFgoUMEI5Mjg2RTgzMDMzREIxQjgyRjggAUoICPWw3doDEAE"
                                    href={googleEarthUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                >
                                    Voir avec Google Earth
                                </a>
                            </div>
                        </div>
                    </Popup>
                </Marker>
            {/* {
                subdivision.lands.map((land, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[land.real_estate.latitude, land.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{land.real_estate.wording}</h6>
                                    <p>{land.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(land.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {land.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            } */}
        </MapContainer>
    );
};

export default SubdivisionMap;

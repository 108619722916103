import React from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import SubdivisionMap from "../../maps/SubdivisionMap";

const SubdivisionModal = ({ subdivision, show, setShow, toggle, onClosed }) => {

    const publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div className="ltn__modal-area ltn__quick-view-modal-area" >
            <div
                className="modal fade"
                id="quick_view_modal"
                tabIndex={-1}
            >
                <div
                    className="modal-dialog modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{
                                    top: '-16px',
                                    right: '-16px'
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="ltn__quick-view-modal-inner">
                                <div className="modal-product-item">
                                    <div className="ltn__upcoming-project-item m-0">
                                        <div className="row">
                                            {/* <div className="col-lg-7 ltn__upcoming-project-slider-1-active slick-arrow-3 slick-initialized slick-slider"> */}
                                            <div className="col-lg-7 ltn__product-slider-item-four-active slick-arrow-1">
                                                <Slider
                                                    // arrows={false}
                                                    dots={true}
                                                    infinite={false}
                                                    speed={300}
                                                    slidesToShow={1}
                                                    slidesToScroll={1}
                                                    prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                                                    nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                                                >
                                                {
                                                    [1, 2, 3].map((it, index) => {
                                                        return(
                                                            <div
                                                                key={index}
                                                                className="ltn__upcoming-project-img">
                                                                <img
                                                                    // src={publicUrl+"assets/img/product-3/3.jpg"} alt="#"
                                                                    src={`${publicUrl}assets/img/lands/image-terrain-${subdivision.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                                                                    // src={`${publicUrl}assets/img/lands/image-terrain-sangalkam.jpg`}
                                                                    alt="Illustration"
                                                                />
                                                            </div>
                                                        );
                                                    })
                                                }
                                                </Slider>
                                            </div>
                                            <div className="col-lg-5 section-bg-1">
                                                <div className="ltn__upcoming-project-info ltn__menu-widget pt-3">
                                                    {/* <h6 className="section-subtitle ltn__secondary-color mb-0">About Projects</h6> */}
                                                    {/* <h3 className="mb-30 mt-0">{subdivision.wording}</h3> */}
                                                    <h3 className="mb-30 mt-0">{subdivision.wording}</h3>
                                                    <ul className="mt">
                                                        <li>
                                                            <div className="d-flex flex-row align-items-center flex-wrap gap-2">
                                                                <div className="fw-normal">Emplacement: </div>
                                                                {/* <div className="fw-medium">{`${subdivision.municipality.name}, ${subdivision.street}`}</div> */}
                                                                <div className="fw-medium">{`${subdivision.municipality.name}, ${subdivision.street}`}</div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex flex-row align-items-center flex-wrap gap-2">
                                                                <div className="fw-normal">Nombre de parcelles: </div>
                                                                {/* <div className="fw-medium">{subdivision.lands_count}</div> */}
                                                                <div className="fw-medium">{subdivision.lands_count}</div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex flex-row align-items-center flex-wrap gap-2">
                                                                <div className="fw-normal">Superficie: </div>
                                                                {/* <div className="fw-medium">{`${parseInt(subdivision.superficies)} ha`}</div> */}
                                                                <div className="fw-medium">{`${parseInt(subdivision.superficies)} ha`}</div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div className="btn-wrapper animated go-top">
                                                        <Link to={`/terrains/lotissements/${subdivision.id}`}
                                                            // <Link to={`/terrains/lotissements/1`}
                                                            className="theme-btn-1 btn btn-effect-1"
                                                        >
                                                            Voir les parcelles
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="" style={{height: "300px"}}>
                                <SubdivisionMap subdivision={subdivision} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubdivisionModal;

import React, { useEffect, useState } from "react";

import L from "leaflet";
import { LayerGroup, LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../utils/AssetHelpers";
import { useDataContext } from "../../../data/providers/DataProvider";
import { useResearchDataContext } from "../providers/ResearchDataProvider";
import MarkerClusterGroup from "react-leaflet-cluster";
import { getStatus } from "../../../utils/shared";

import 'azure-maps-control/dist/atlas.min.css';

// import MarkerCluster from "./Clusters";
const { BaseLayer } = LayersControl;

const LandsMap = () => {

    // const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { lands } = useDataContext();
    const {
        // landDepartment,
        landMunicipality,
        landRegion,
        landSuperficiesRange,
        landPriceRange,
        serviced
    } = useResearchDataContext();

    const [filteredLands, setFilteredLands] = useState([]);
    // const [markers, setMarkers] = useState([]);

    // const addMarkers = useCallback(() => {
    //     // addMarkers();
    //     const _markers = [];
    //     lands.forEach((land) =>
    //         _markers.push({
    //             position: {
    //                 lng: land.real_estate.longitude,
    //                 lat: land.real_estate.latitude
    //             }
    //         })
    //     );
    //     setMarkers(_markers);
    //     // setMarkers([{position: {lat: 14.692778, lng:-17.446667}}]);
    // }, [lands]);

    useEffect(() => {
        let f_lands = lands;

        if(landRegion){
            f_lands = f_lands.filter((l) => l.real_estate.region_id === landRegion.id);
        }
        if(landMunicipality){
            f_lands = f_lands.filter((l) => l.real_estate.municipality_id === landMunicipality.id);
        }
        if(serviced){
            f_lands = f_lands.filter((l) => l.serviced === serviced);
        }
        if(landPriceRange){
            const landPriceRangeLimits = landPriceRange.split('-');
            f_lands = f_lands.filter((l) => l.real_estate.price >= parseInt(landPriceRangeLimits[0]) && l.real_estate.price <= parseInt(landPriceRangeLimits[1]));
        }
        setFilteredLands(f_lands);

        // addMarkers();
    }, [landRegion, landMunicipality, landSuperficiesRange, landPriceRange, lands, serviced/*, addMarkers*/]);

    const customIcon = new L.Icon({
        iconUrl: require("./location-blue.svg").default,
        iconSize: new L.Point(40, 47)
    });

      // const customIcon = new L.Icon({
//   iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
//   iconSize: [25, 41]
// });
    return (
        <MapContainer
            className=""
            // center={[14.692778, -17.446667]}
            // center={[14.232438, -14.545898]}
            center={[14.4750607,-14.4529612]}
            zoom={6}
            maxZoom={20}
            scrollWheelZoom={false}
        >
            <LayersControl>
                <BaseLayer name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
                {/* <BaseLayer name="Stadia Alidade Smooth">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.{ext}"
                        attribution="&copy; <a href=&quot;https://www.stadiamaps.com/&quot; target=&quot;_blank&quot;>Stadia Maps</a> &copy; <a href=&quot;https://openmaptiles.org/&quot; target=&quot;_blank&quot;>OpenMapTiles</a> &copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia OSM Bright">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Outdoors">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="png"
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Stadia Alidade Satellite">
                    <TileLayer
                        url="https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.{ext}"
                        attribution='&copy; CNES, Distribution Airbus DS, © Airbus DS, © PlanetObserver (Contains Copernicus Data) | &copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                        ext="jpg"
                        language='fr-FR'
                    />
                </BaseLayer> */}
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom={20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        language='fr-FR'
                    />
                </BaseLayer>
                <BaseLayer name="Azure Road">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="Azure Hybrid" checked>
                    <LayerGroup>
                        {/* <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.darkgrey&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='en-US'
                            maxZoom={20}
                        /> */}
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="Azure Satellite">
                    <TileLayer
                        url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.imagery&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                        attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                        apiVersion='2.0'
                        subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                        language='fr-FR'
                        maxZoom={20}
                    />
                </BaseLayer>
                <BaseLayer name="ArcGis Hybrid">
                    <LayerGroup>
                        <TileLayer
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                            attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                            maxZoom={20}
                        />
                        <TileLayer
                            url='https://atlas.microsoft.com/map/tile?api-version={apiVersion}&tilesetId=microsoft.base.labels.road&x={x}&y={y}&zoom={z}&language={language}&subscription-key={subscriptionKey}'
                            attribution='See https://docs.microsoft.com/en-us/rest/api/maps/render-v2/get-map-tile for details.'
                            apiVersion='2.0'
                            subscriptionKey='GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY'
                            language='fr-FR'
                            maxZoom={20}
                        />
                    </LayerGroup>
                </BaseLayer>
                <BaseLayer name="ArcGis Satellite">
                    <TileLayer
                        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                        // attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        maxZoom={20}
                        language='fr-FR'
                    />
                </BaseLayer>
            </LayersControl>
            <MarkerClusterGroup>
            {
                filteredLands.map((land, index) => {
                    return (
                        <Marker
                            icon={customIcon}
                            key={index}
                            position={[land.real_estate.latitude, land.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{`Terrain nu - lot n° ${land.number}`}</h6>
                                    {/* <p>{land.real_estate.description}</p> */}
                                    <ul className="personalized px-3 py-2">
                                            <li className="mt-0">
                                                <label>Superficie:</label>{" "}
                                                <span>{parseInt(land.real_estate.superficies)}{" "}m<sup>2</sup></span>
                                            </li>
                                            <li>
                                                <label>Position:</label>{" "}
                                                <span>{land.position === 'CORNER' ? "Angle" : "Simple"}</span>
                                            </li>
                                            <li>
                                                <label>Catégorie:</label>{" "}
                                                <span>{land.category === 'TF' ? "Titre foncier" : "Bail"}</span>
                                            </li>
                                            <li>
                                                <label>Prix:</label>
                                                <span>
                                                    {formatNumber(land.real_estate.price)}{" "}
                                                    <label>FCFA</label>
                                                {
                                                    land.real_estate.status === "RENT" && 
                                                    <label>/Mois</label>
                                                }
                                                </span>
                                                <small>
                                                {
                                                    land.real_estate.monthly_price &&
                                                    <span>{" - "}{formatNumber(land.real_estate.monthly_price)} <label>FCFA</label> <label>/ Mois</label></span>
                                                }
                                                </small>
                                            </li>
                                            <li>
                                                <label>Viabilisé:</label>{" "}
                                                <span>{land.real_estate.serviced? "Oui" : "Non"}</span>
                                            </li>
                                            <li>
                                                <label>Statut:</label>{" "}
                                                <span>{getStatus(land.real_estate)}</span>
                                            </li>
                                        </ul>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
            </MarkerClusterGroup>
            {/* <MarkerCluster markers={markers} addMarkers={addMarkers} /> */}
        </MapContainer>
    );
};

export default LandsMap;

import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Navbar2 from '../../../components/global-components/Navbar2';
import PageHeader from '../../../components/global-components/PageHeader';
import Content from '../../../components/real-estate-components/land-components/subdivisions/plots/Content';
import Newsletter from '../../../components/global-components/Newsletter';
import Footer from '../../../components/global-components/Footer';
import { useDataContext } from '../../../data/providers/DataProvider';
import { useLoaderSpinnerContext } from '../../../components/global-components/providers/LoaderSpinnerProvider';

const Subdivision = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { subdivisions } = useDataContext();
    const { id: subdivisionId } = useParams();
    const [subdivision, setSubdivision] = useState(undefined);

    useEffect(() => {

        if(subdivision){
            setShowLoaderSpinner(false);
        }

    }, [subdivision, setShowLoaderSpinner]);

    useEffect(() => {

        const _subdivision = subdivisions.find((l) => l.id === Number(subdivisionId));
        setSubdivision(_subdivision);

    }, [subdivisionId, subdivisions]);

    // const publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div>
            <Navbar2 />
        {
            subdivision &&
            <PageHeader
                headertitle={`${subdivision.wording}`}
                breadcrumbs={['terrains', 'lotissements', subdivision.wording]}
            />
        }
        {
            subdivision && <Content subdivision={subdivision} />
        }
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Subdivision;

import React from "react";

// import { PeopleFill, Search } from "react-bootstrap-icons";

// import { useTranslation } from "react-i18next";

/**
 * 
 * @param param0 
 * @returns 
 */
const NoSubdivision = ({fromSearch = false}) => {

    // const { t } = useTranslation();

    return (
        <div className="no-land">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center justify-content-center py-5 mt-12 gap-3">
                {
                    fromSearch
                    ?
                    <div className="icon-wrapper">
                        <i className="fas fa-search"></i>
                    </div>
                    :
                    <div className="icon-wrapper text-muted">
                        {/* <PeopleFill size={64} /> */}
                        <i className="fas fa-search"></i>
                    </div>
                }
                    <p className="no-land-text text-center mb-0 fs-8">{!fromSearch ? 'Aucun lotissement disponible pour le moment' : 'Aucun lotissement trouvé pour votre recherche'}</p>
                </div>
            </div>
        </div>
    );
};

export default NoSubdivision;

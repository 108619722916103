import React from 'react';
import { Link } from 'react-router-dom';

/**
 * 
 * @returns 
 */
const FeetOnTheGround = () => {

    const publicUrl = process.env.PUBLIC_URL + '/';

    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="apartments-plan-img px-5">
                    <img src={`${publicUrl}assets/img/illustrations/house-v3.jpg`} alt="Illustration" />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="apartments-plan-img px-5">
                    <img src={`${publicUrl}assets/img/illustrations/house-v2.jpg`} alt="Illustration" />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="apartments-plan-img px-5">
                    <img src={`${publicUrl}assets/img/illustrations/house-v1.jpg`} alt="Illustration" />
                </div>
            </div>
        </div>
    );
};

export default FeetOnTheGround;

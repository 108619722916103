import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDataContext } from "../../../../data/providers/DataProvider";
import Slider from "react-slick";
import SubdivisionModal from "../../../real-estate-components/land-components/subdivisions/SubdivisionModal";

/**
 * 
 * @returns 
 */
const SubdivisionsSlider = () => {

    const { subdivisions } = useDataContext();
    let publicUrl = process.env.PUBLIC_URL + "/";

    const [selectedSubdivision, setSelectedSubdivion] = useState(null);
    const [showSubdivisionModal, setShowSubdivisionModal] = useState(false);

    useEffect(() => {
        setSelectedSubdivion(subdivisions[0]);
    }, [subdivisions]);

    /**
     * 
     * @returns 
     */
    const toggleSubdivisionModal = () => setShowSubdivisionModal(!showSubdivisionModal);

    /**
     * 
     * @returns 
     */
    const onClosedSubdivisionModal = () => setSelectedSubdivion(null);

    return (
        <div
            id="homeSubdivionsSliderSubSection"
            className="home-real-estates-slider-subsection home-lands-slider-subsection"
        >
            <div className="ltn__product-slider-area ltn__product-gutter">
                <div className="container-fluid container-lg">
                {
                    subdivisions.length > 0
                    ?
                    <Slider
                        arrows={subdivisions.length > 3}
                        dots={true}
                        infinite={subdivisions.length > 3}
                        speed={300}
                        slidesToShow={3}
                        slidesToScroll={1}
                        prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                        nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                        responsive={[
                            {
                                breakpoint: 992,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 580,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 2,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 512,
                                settings: {
                                    arrows: false,
                                    dots: true,
                                    slidesToShow: 1,
                                    slidesToScroll: 1
                                }
                            }
                        ]}
                        className="row ltn__product-slider-item-four-active slick-arrow-1"
                    >
                    {
                        subdivisions.slice(0, 6).map((subdivision, index) => {
                            return (
                                // {/* ltn__product-item */}
                                <div
                                    key={index}
                                    className="col-lg-4"
                                >
			                        <div className="ltn__search-by-place-item p-0">
			                            <div className="search-by-place-img">
                                            <div className="">
                                                <img
                                                    // src={publicUrl + "assets/img/product-3/1.jpg"}
                                                    src={`${publicUrl}assets/img/lands/image-terrain-${subdivision.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                                                    // src={land.real_estate.images[0].data}
                                                    alt="Illustration"
                                                />
                                            </div>
                                            <div className="search-by-place-badge" style={{top: '5px'}}>
                                                <ul>
                                                    <li className="mt-1">{subdivision.lands_count} parcelles</li>
                                                </ul>
                                            </div>
                                            {
                                                subdivision.belongs_to_alphaimmo &&
                                                <div
                                                    className="real-estate-agent position-absolute"
                                                    style={{top:'5px', right:'5px'}}
                                                >
                                                    <div className="agent-img bg-white rounded-circle p-1 m-0">
                                                        <a href="/alfaimmo">
                                                            <img
                                                                src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                alt="Mascotte ALFAIMMO"
                                                                width={32}
                                                                title="ALFAIMMO"
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            }
			                            </div>
			                            <div className="search-by-place-info px-2 pb-3">
			                                {/* <h6>
                                                <Link to="/contact">San Francisco</Link>
                                            </h6> */}
                                            <p
                                                className="text-whitee text-truncate cursor-default"
                                                title={`${subdivision.municipality.name}, ${subdivision.street}`}
                                            >
                                                <i className="flaticon-pin" />{" "}
                                                {`${subdivision.municipality.name}, ${subdivision.street}`}
                                            </p>
                                            {/* <p
                                                className="m-0 text-whitee text-truncate cursor-default"
                                                title={`${subdivision.municipality.name}, ${subdivision.street}`}
                                            >
                                            <small>
                                                <i className="flaticon-pin" />{" "}
                                                {`${subdivision.municipality.name}, ${subdivision.street}`}
                                            </small>
                                            </p> */}
                                            <div className="search-by-place-btn">
                                                {/* <Link to="/product-details">Voir les détails <i className="flaticon-right-arrow" /></Link> */}
                                                <button
                                                    type='button'
                                                    title="Vue rapide"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#quick_view_modal"
                                                    className="bg-transparent ltn__secondary-color"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedSubdivion(subdivision);
                                                        setShowSubdivisionModal(true);
                                                    }}
                                                >
                                                    Voir les détails
                                                    {" "}
                                                    <i className="flaticon-right-arrow" />
                                                </button>
                                            </div>
			                            </div>
			                        </div>
			                    </div>
                                // {/* ltn__product-item */}
                            );
                        })
                    }
                    </Slider>
                    :
                    <div className="d-flex align-items-center justify-content-center py-5">
                        <div className="w-100 d-flex align-items-center justify-content-center alert alert-primary">
                            <p className="m-0">Aucun lotissement disponible pour le moment</p>
                        </div>
                    </div>
                }
                </div>
                {
                    subdivisions.length > 0 &&
                    <div className="btn-wrapper text-center go-top">
                        <Link
                            to="/terrains/lotissements"
                            className="btn theme-btn-1 btn-effect-1 text-uppercase"
                            >
                            Voir tout
                        </Link>
                    </div>
                }
            </div>
            {
                selectedSubdivision &&
                <SubdivisionModal
                    show={showSubdivisionModal}
                    setShow={setShowSubdivisionModal}
                    toggle={toggleSubdivisionModal}
                    onClosed={onClosedSubdivisionModal}
                    subdivision={selectedSubdivision}
                />
            }
        </div>
    );
};

export default SubdivisionsSlider;

import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "./Sidebar";
import { useDataContext } from "../../../../data/providers/DataProvider";
import { formatNumber } from "../../../../utils/AssetHelpers";
import { useResearchDataContext } from "../../providers/ResearchDataProvider";
import NoSubdivision from "./NoSubdivision";
import usePagination from "../../../global-components/hooks/usePagination";
import Pagination from "../../../global-components/ui/Pagination";
import { useLoaderSpinnerContext } from "../../../global-components/providers/LoaderSpinnerProvider";
import { getBadgeColorByStatus, getStatus } from "../../../../utils/shared";
import Slider from "react-slick";
import SubdivisionMap from "../../maps/SubdivisionMap";
import PlanModal from "../../modals/PlanModal";

const Content = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { subdivisions } = useDataContext();
    const {
        // subdivisionDepartment,
        subdivisionMunicipality,
        subdivisionRegion,
        subdivisionSuperficiesRange,
    } = useResearchDataContext();

    const publicUrl = process.env.PUBLIC_URL + "/";
    const sortingElements = [
        {
            value: 'default',
            wording: "Défaut"
        },
        {
            value: 'price-asc',
            wording: "Prix : du plus bas au plus élevé"
        },
        {
            value: 'price-desc',
            wording: "Prix : du plus élevé au plus bas"
        },
    ];
    const [filteredSubdivisions, setFilteredSubdivisions] = useState([]);
    const [searchedText, setSearchText] = useState('');
    const [fromSearch, setFromSearch] = useState(false);

    /**
     * 
     * @param subdivision 
     * @returns 
     */
    const checkSearchedText = useCallback((subdivision) => {

        return (subdivision.wording.toLowerCase().indexOf(searchedText) > -1 ||
                subdivision.description.toLowerCase().indexOf(searchedText) > -1 ||
                subdivision.superficies.toLowerCase().indexOf(searchedText) > -1);

    }, [searchedText]);

    useEffect(() => {
        let f_subdivisions = subdivisions;
        setFromSearch(false);

        if(subdivisionRegion){
            f_subdivisions = f_subdivisions.filter((sd) => sd.region_id === subdivisionRegion.id);
        }
        if(subdivisionMunicipality){
            f_subdivisions = f_subdivisions.filter((sd) => sd.municipality_id === subdivisionMunicipality.id);
        }
        if(searchedText.length > 0){
            f_subdivisions = f_subdivisions.filter(checkSearchedText);
            setFromSearch(true);
        }
        setFilteredSubdivisions(f_subdivisions);

    }, [
        subdivisionRegion,
        subdivisionMunicipality,
        subdivisionSuperficiesRange,
        searchedText,
        checkSearchedText,
        subdivisions
    ]);

    const [rowsPerPage, setRowsPerPage] = useState(Number(sessionStorage.getItem('subdivisionsPagination')??12));

    useEffect(() => {
        const rows = sessionStorage.getItem('subdivisionsPagination');
        setRowsPerPage(rows !== null ? Number(rows) : 12);
    }, []);

    const [curentPageNumber, setCurrentPageNumber] = useState(1);

    const { pageData, pageCount } = usePagination(filteredSubdivisions, curentPageNumber, rowsPerPage);

    useEffect(() => {
        if(curentPageNumber > pageCount){
            setCurrentPageNumber(pageCount);
        }
    }, [pageCount, curentPageNumber]);

    const [selectedSubdivision, setSelectedSubdivion] = useState(null);
    const [showSubdivisionPlanModal, setShowSubdivisionPlanModal] = useState(false);

    useEffect(() => {
        setSelectedSubdivion(pageData[0]);
    }, [pageData]);

    /**
     * 
     * @returns 
     */
    const toggleSubdivisionPlanModal = () => setShowSubdivisionPlanModal(!showSubdivisionPlanModal);

    /**
     * 
     * @returns 
     */
    const onClosedSubdivisionPlanModal = () => setSelectedSubdivion(null);

    return (
        <div>
            <div className="ltn__product-area ltn__product-gutter">
                <div className="container-fluid container-lg px-lg-4 px-xl-5">
                    <div className="row">
                        {/* <div className="col-md-4 col-lg-4 col-xl-3 mb-100">
                            <Sidebar />
                        </div> */}
                        <div className="col-12 mb-100">
                            <div className="ltn__shop-options d-flex align-items-center justify-content-between gap-4">
                                <ul className="justify-content-start">
                                    <li className="d-none">
                                        <div className="showing-product-number text-right">
                                            <span>Showing 1–12 of 18 results</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center gap-1">
                                           <label>Trier par:</label>
                                            <div className="short-by text-center">
                                                <select className="nice-select">
                                                {
                                                    sortingElements.map((element, index) => {
                                                        return (
                                                            <option key={index} value={element.value}>{element.wording}</option>
                                                        );
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <Sidebar />
                                    </li>
                                    {/* <li>
                                        <div className="d-flex align-items-center gap-1">
                                            <label>Afficher par page:</label>
                                            <div className="show-per-page text-center">
                                                <select className="nice-select">
                                                {
                                                    itemsPerPage.map((it, index) => {
                                                        return (
                                                            <option key={index} value={it}>{it}</option>
                                                        );
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* Search Widget */}
                                    <div className="ltn__search-widget mb-30 mx-auto">
                                        <form action="#">
                                            <input
                                                type="text"
                                                name="search"
                                                placeholder="Rerchercher..."
                                                defaultValue=""
                                                onInput={(e) => setSearchText(e.currentTarget.value)}
                                            />
                                            <button type="submit">
                                                <i className="fas fa-search" />
                                            </button>
                                        </form>
                                    </div>
                                    {/* Search Widget */}
                                </div>
                            {
                                !pageData.length
                                ?
                                <NoSubdivision fromSearch={fromSearch} />
                                :
                                pageData.map((subdivision, index) => {
                                    return (
                                        // {/* ltn__product-item */}
                                        <div className="ltn__upcoming-project-item" key={index}>
                                            <div className="row">
                                                {/* <div className="col-lg-7">
                                                    <div className="ltn__upcoming-project-img">
                                                        <img
                                                            // src={publicUrl+"assets/img/product-3/3.jpg"} alt="#"
                                                            src={`${publicUrl}assets/img/lands/image-terrain-${subdivision.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                                                            alt="Illustration"
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-7 ltn__product-slider-item-four-active slick-arrow-1">
                                                    <Slider
                                                        // arrows={false}
                                                        dots={true}
                                                        infinite={false}
                                                        speed={300}
                                                        slidesToShow={1}
                                                        slidesToScroll={1}
                                                        prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                                                        nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                                                    >
                                                    {
                                                        [1, 2, 3].map((it, index) => {
                                                            return(
                                                                <div
                                                                    key={index}
                                                                    className="ltn__upcoming-project-img">
                                                                    <img
                                                                        // src={publicUrl+"assets/img/product-3/3.jpg"} alt="#"
                                                                        src={`${publicUrl}assets/img/lands/image-terrain-${subdivision.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                                                                        // src={`${publicUrl}assets/img/lands/image-terrain-sangalkam.jpg`}
                                                                        alt="Illustration"
                                                                    />
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    </Slider>
                                                </div>
                                                <div className="col-lg-5 section-bg-1">
                                                    <div className="ltn__upcoming-project-info ltn__menu-widget pt-3">
                                                        {/* <h6 className="section-subtitle ltn__secondary-color mb-0">About Projects</h6> */}
                                                        <h3 className="mb-30 mt-0">{subdivision.wording}</h3>
                                                        <ul className="mt">
                                                            <li>
                                                                <div className="d-flex flex-row align-items-center flex-wrap gap-2">
                                                                    <div className="fw-normal">Emplacement: </div>
                                                                    <div className="fw-medium">{`${subdivision.municipality.name}, ${subdivision.street}`}</div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="d-flex flex-row align-items-center flex-wrap gap-2">
                                                                    <div className="fw-normal">Nombre de parcelles: </div>
                                                                    <div className="fw-medium">{subdivision.lands_count}</div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="d-flex flex-row align-items-center flex-wrap gap-2">
                                                                    <div className="fw-normal">Superficie: </div>
                                                                    <div className="fw-medium">{`${parseInt(subdivision.superficies)} ha`}</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="btn-wrapper animated go-top">
                                                            <Link to={`/terrains/lotissements/${subdivision.id}`}
                                                                className="theme-btn-1 btn btn-effect-1"
                                                            >
                                                                Voir les parcelles
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-12">
                                                    <div className="btn-wrapper animated go-top text-center">
                                                        <button
                                                            type='button'
                                                            title="Vue rapide"
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target={`viewPlan${subdivision.id}`}
                                                            className="theme-btn-1 btn btn-effect-1"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // setSelectedSubdivion(subdivisions[index]);
                                                                setSelectedSubdivion(subdivision);
                                                                setShowSubdivisionPlanModal(selectedSubdivision);
                                                                console.log(selectedSubdivision.wording)
                                                            }}
                                                        >
                                                            Voir le plan
                                                        </button>
                                                    </div>
                                                </div> */}
                                                <div className="col-12 pe-0">
                                                    <div className="mt-5" style={{height: "60vh"}}>
                                                        <SubdivisionMap
                                                            subdivision={subdivision}
                                                            googleEarthUrl={(subdivision.municipality_id === 432) ? 'https://earth.google.com/earth/d/1c7jgyovVzmPeQch0y1DjPKfAx8OVlzxK?usp=sharing' : 'https://earth.google.com/earth/d/1F-CqT6GWX6SPyHxB7CnMN30ItsElEved?usp=sharing'}
                                                            circleRadius={1500}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        // {/* ltn__product-item */}
                                    );
                                })
                            }
                            </div>
                            <div className="ltn__pagination-area text-center">
                                <div className="ltn__pagination">
                                    {/* begin::pagination */}
                                    <div className="pagination-box-wrapper mt-5">
                                        <Pagination
                                            id='landsPagination'
                                            pageCount={pageCount}
                                            currentPageNumber={curentPageNumber}
                                            setCurrentPageNumber={setCurrentPageNumber}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    </div>
                                    {/* end::pagination */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {
                selectedSubdivision &&
                <PlanModal
                    id="viewPlan"
                    show={showSubdivisionPlanModal}
                    setShow={setShowSubdivisionPlanModal}
                    toggle={toggleSubdivisionPlanModal}
                    onClosed={onClosedSubdivisionPlanModal}
                    // subdivision={selectedSubdivision}
                    // planUrl={`${publicUrl}assets/img/lands/${selectedSubdivision && selectedSubdivision.municipality_id === 466 ? 'LOTISSEMENT-KOUNOUNE-14-PARCELLES-2' : 'LOTISSEMENT-POUT-KHININE-50-PARCELLES'}.png`}
                >
                    <img
                        src={`${publicUrl}assets/img/lands/${selectedSubdivision && selectedSubdivision.municipality_id === 466 ? 'LOTISSEMENT-KOUNOUNE-14-PARCELLES-2' : 'LOTISSEMENT-POUT-KHININE-50-PARCELLES'}.png`}
                        // className="h-100"
                        alt="Plan"
                    />
                </PlanModal>
            } */}
        </div>
    );
};

export default Content;

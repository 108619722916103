import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDataContext } from "../../../../data/providers/DataProvider";
import { useResearchDataContext } from "../../providers/ResearchDataProvider";

import Select from 'react-select';

import {
    customSelectClearIndicator,
    customSelectControl,
    customSelectDropdownIndicator,
    customSelectMenu,
    customSelectMenuList,
    customSelectOption,
    customSelectPlaceholder,
    customSelectStyles,
    customSelectValueContainer
} from "../../../global-components/select-customizer";
import RangeSlider from "../../../global-components/ui/RangeSlider";


const Sidebar = () => {

    const researchData = useResearchDataContext();
    const {subdivisions, regions, municipalities} = useDataContext();

    const regionsSelectRef = useRef(null);
    const municipalitiesSelectRef = useRef(null);
    const [municipalitiesByRegion, setMunicipalitiesByRegion] = useState([]);
    const [disableMunicipalitiesSelect, setDisableMunicipalitiesSelect] = useState(true);

    useEffect(() => {

        if(regionsSelectRef.current){
            regionsSelectRef.current.setValue(researchData.subdivisionRegion);
        }
        if(municipalitiesSelectRef.current){
            municipalitiesSelectRef.current.setValue(researchData.subdivisionMunicipality);
        }
    }, [researchData.subdivisionMunicipality, researchData.subdivisionRegion]);

    /**
     * 
     * @param region 
     */
    const changeRegion = (region) => {
        researchData.setLandRegion(region);

        if(region === null || region === undefined){
            setMunicipalitiesByRegion([]);
            municipalitiesSelectRef.current.clearValue();
            setDisableMunicipalitiesSelect(true);
            // setIsValidComplainantRegion(false);
        }
        else{
            setMunicipalitiesByRegion(municipalities.filter((municipality) => municipality.region_id === region.id));
            setDisableMunicipalitiesSelect(false);

            if(researchData.subdivisionMunicipality !== null &&
                researchData.subdivisionMunicipality !== null &&
                researchData.subdivisionMunicipality?.region_id !== region.id){
                    municipalitiesSelectRef.current.clearValue();
            }
            // setIsValidComplainantRegion(true);
        }
    };

    return (
        <div className="d-flex flex-row flex-wrap px-3 gap-3">
            <div className="" style={{minWidth: '255px'}}>
                <Select
                    ref={regionsSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='region'
                    options={regions}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    placeholder='Région'
                    styles={customSelectStyles}
                    onChange={changeRegion}
                />
            </div>
            <div className="" style={{minWidth: '255px'}}>
                <Select
                    ref={municipalitiesSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isDisabled={disableMunicipalitiesSelect}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='municipality'
                    options={municipalitiesByRegion}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    placeholder='Commune'
                    styles={customSelectStyles}
                    onChange={researchData.setSubdivisionMunicipality}
                />
            </div>
        </div>
    );
}

export default Sidebar;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import Slider from "react-slick";

const HouseSlider = ({house}) => {
    let publicUrl = process.env.PUBLIC_URL + "/";

    useEffect(() => {
        const $ = window.$
        $('a[data-rel^=lightcase]').lightcase({
            transition: 'elastic', /* none, fade, fadeInline, elastic, scrollTop, scrollRight, scrollBottom, scrollLeft, scrollHorizontal and scrollVertical */
            swipe: true,
            maxWidth: 1170,
            maxHeight: 600,
        });
    }, []);

    return (
        <div className="ltn__img-slider-area mb-90">
            <div className="container-fluid">
                <Slider
                    rtl={false}
                    arrows={true}
                    dots={false}
                    infinite={true}
                    speed={300}
                    slidesToShow={1}
                    slidesToScroll={1}
                    centerMode={true}
                    centerPadding={'450px'}
                    prevArrow={<button type="button" className="slick-prev"><i className="fas fa-arrow-left" alt="Arrow Icon"></i></button>}
                    nextArrow={<button type="button" className="slick-next"><i className="fas fa-arrow-right" alt="Arrow Icon"></i></button>}
                    responsive={[
                        {
                            breakpoint: 1600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                centerPadding: '250px',
                            }
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                centerPadding: '200px',
                            }
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                arrows: false,
                                dots: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                centerPadding: '150px',
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: false,
                                dots: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: false,
                                centerPadding: '0px',
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                arrows: false,
                                dots: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: false,
                                centerPadding: '0px',
                            }
                        }
                    ]}
                    className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all"
                >
                    {/* <div className="col-lg-12"> */}
                    {
                        house.real_estate.images.map((image, index) => {
                            return (
                                <div key={index} className="col-lg-12">
                                    <div className="ltn__img-slide-item-4">
                                        <a
                                            // href={publicUrl + "assets/img/img-slide/31.jpg"}
                                            href={`${publicUrl}assets/img/houses/${house.id}/${image.file_name}`}
                                            data-rel="lightcase:myCollection"
                                        >
                                            <img
                                                // src={
                                                //     publicUrl +
                                                //     // "assets/img/img-slide/31.jpg"
                                                //     "assets/img/img-slide/0xNwSoUVZPlLcbuPlmjn0i8y5GOcHTQpe7kOXvq3.jpg"
                                                // }
                                                src={`${publicUrl}assets/img/houses/${house.id}/${image.file_name}`}
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            );
                        })
                    }
                    {/* </div> */}
                </Slider>
            </div>
        </div>
    );
};

export default HouseSlider;

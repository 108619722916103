import React, { useCallback, useContext, useEffect, useState } from "react";

import DataContext from "../contexts/DataContext";
import api from "../../services/api";
import { useLoaderSpinnerContext } from "../../components/global-components/providers/LoaderSpinnerProvider";

/**
 * 
 * @returns 
 */
export const useDataContext = () => useContext(DataContext);

/**
 * Create the provider component
 *
 * @param param0 
 * @returns 
 */
const DataProvider = ({ children }) => {
    /**
     * 
     */
    const [lands, setLands] = useState([]);
    const [landPriceRanges, setLandPriceRanges] = useState();
    const [houses, setHouses] = useState([]);
    const [housePriceRanges, setHousePriceRanges] = useState();
    const [apartments, setApartments] = useState([]);
    const [apartmentPriceRanges, setApartmentPriceRanges] = useState();
    const [realEstates, setRealEstates] = useState([]);
    const [realEstatePriceRanges, setRealEstatePriceRanges] = useState();
    const [subdivisions, setSubdivisions] = useState([]);

    const [regions, setRegions] = useState([]);
    // const [departments, setDepartments] = useState<Department[]>([]);
    const [municipalities, setMunicipalities] = useState([]);

    /**
     * 
     */
    const { setShow: setShowSpinnerLoader } = useLoaderSpinnerContext();

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchLands = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-lands', null).then((res) => {
            setLands(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchLandPriceRanges = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-land-price-ranges', null).then((res) => {
            setLandPriceRanges(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchHouses = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-houses', null).then((res) => {
            setHouses(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchHousePriceRanges = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-house-price-ranges', null).then((res) => {
            setHousePriceRanges(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchApartments = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-apartments', null).then((res) => {
            setApartments(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchApartmentPriceRanges = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-apartment-price-ranges', null).then((res) => {
            setApartmentPriceRanges(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchRealEstates = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-real-estates', null).then((res) => {
            setRealEstates(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchRealEstatePriceRanges = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-real-estate-price-ranges', null).then((res) => {
            setRealEstatePriceRanges(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchSubdivisions = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-subdivisions', null).then((res) => {
            setSubdivisions(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchRegions = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-regions', null).then((res) => {
            setRegions(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    // const fetchDepartments = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

    //     if(showSpinnerLoader){
    //         setShowSpinnerLoader(true);
    //     }
    //     api.get('/get-departments', null).then((res) => {
    //         setRegions(res.data);
    //         setTimeout(() => {
    //             if(showSpinnerLoader){
    //                 setShowSpinnerLoader(false);
    //             }
    //         }, spinnerTime);
    //     }).catch((error) => {
    //         setShowSpinnerLoader(false);
    //         // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
    //         console.log(error);
    //     });
    // }, [setShowSpinnerLoader]);

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchMunicipalities = (showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-municipalities', null).then((res) => {
            setMunicipalities(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    };

    /**
     * 
     */
    const fetchData = () => {

        setShowSpinnerLoader(true);
        fetchApartments(false);
        fetchApartmentPriceRanges(false);
        fetchLands(false);
        fetchLandPriceRanges(false);
        fetchHouses(false);
        fetchHousePriceRanges(false);
        fetchSubdivisions(false);
        // fetchRealEstates(false);
        fetchRealEstatePriceRanges(false);

        fetchRegions(false);
        // fetchDepartments(false);
        fetchMunicipalities(true, 2000);
        setTimeout(() => {
            setShowSpinnerLoader(false);
        }, 2000);

    };

    /**
     * 
     */
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Define the context value
     */
    const contextValue = {
        apartments,
        apartmentPriceRanges,
        // departments,
        houses,
        housePriceRanges,
        lands,
        landPriceRanges,
        municipalities,
        realEstates,
        realEstatePriceRanges,
        subdivisions,
        regions,
        fetchApartments,
        fetchApartmentPriceRanges,
        // fetchDepartments,
        fetchData,
        fetchHouses,
        fetchHousePriceRanges,
        fetchLands,
        fetchLandPriceRanges,
        fetchMunicipalities,
        fetchRealEstates,
        fetchRealEstatePriceRanges,
        fetchRegions,
        fetchSubdivisions,
        setApartments,
        setApartmentPriceRanges,
        // setDepartments,
        setHouses,
        setHousePriceRanges,
        setLands,
        setLandPriceRanges,
        setMunicipalities,
        setRealEstates,
        setRealEstatePriceRanges,
        setSubdivisions,
        setRegions
    };

    return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

export default DataProvider;

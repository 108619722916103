import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Sidebar from "./Sidebar";
import { useDataContext } from "../../../../../data/providers/DataProvider";
import { formatNumber } from "../../../../../utils/AssetHelpers";
import { useResearchDataContext } from "../../../providers/ResearchDataProvider";
import NoPlot from "./NoPlot";
import usePagination from "../../../../global-components/hooks/usePagination";
import Pagination from "../../../../global-components/ui/Pagination";
import { useLoaderSpinnerContext } from "../../../../global-components/providers/LoaderSpinnerProvider";
import { getBadgeColorByStatus, getStatus } from "../../../../../utils/shared";
import SubdivisionMap from "../../../maps/SubdivisionMap";

const Content = ({subdivision}) => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { lands } = useDataContext();
    const {
        // landDepartment,
        landMunicipality,
        landRegion,
        landSuperficiesRange,
        landPriceRange,
        serviced
    } = useResearchDataContext();

    const publicUrl = process.env.PUBLIC_URL + "/";
    const sortingElements = [
        {
            value: 'default',
            wording: "Défaut"
        },
        {
            value: 'price-asc',
            wording: "Prix : du plus bas au plus élevé"
        },
        {
            value: 'price-desc',
            wording: "Prix : du plus élevé au plus bas"
        },
    ];
    const [filteredLands, setFilteredLands] = useState([]);
    const [searchedText, setSearchText] = useState('');
    const [fromSearch, setFromSearch] = useState(false);

    /**
     * 
     * @param land 
     * @returns 
     */
    const checkSearchedText = useCallback((land) => {

        return (land.real_estate.wording.toLowerCase().indexOf(searchedText) > -1 ||
                land.real_estate.description.toLowerCase().indexOf(searchedText) > -1 ||
                land.real_estate.superficies.toLowerCase().indexOf(searchedText) > -1 ||
                `${land.real_estate.price}`.toLowerCase().indexOf(searchedText) > -1);

    }, [searchedText]);

    useEffect(() => {
        let f_lands = lands.filter((l) => l.subdivision_id === subdivision.id);
        setFromSearch(false);

        if(landRegion){
            f_lands = f_lands.filter((l) => l.real_estate.region_id === landRegion.id);
        }
        if(landMunicipality){
            f_lands = f_lands.filter((l) => l.real_estate.municipality_id === landMunicipality.id);
        }
        if(serviced){
            f_lands = f_lands.filter((l) => l.serviced === serviced);
        }
        if(landPriceRange){
            const landPriceRangeLimits = landPriceRange.split('-');
            f_lands = f_lands.filter((l) => l.real_estate.price >= parseInt(landPriceRangeLimits[0]) && l.real_estate.price <= parseInt(landPriceRangeLimits[1]));
        }
        if(searchedText.length > 0){
            f_lands = f_lands.filter(checkSearchedText);
            setFromSearch(true);
        }
        setFilteredLands(f_lands);

    }, [
        landRegion,
        landMunicipality,
        landSuperficiesRange,
        landPriceRange,
        lands,
        serviced,
        searchedText,
        checkSearchedText,
        subdivision
    ]);

    const [rowsPerPage, setRowsPerPage] = useState(Number(sessionStorage.getItem('plotsPagination')??12));

    useEffect(() => {
        const rows = sessionStorage.getItem('plotsPagination');
        setRowsPerPage(rows !== null ? Number(rows) : 12);
    }, []);

    const [curentPageNumber, setCurrentPageNumber] = useState(1);

    const { pageData, pageCount } = usePagination(filteredLands, curentPageNumber, rowsPerPage);

    useEffect(() => {
        if(curentPageNumber > pageCount){
            setCurrentPageNumber(pageCount);
        }
    }, [pageCount, curentPageNumber]);

    return (
        <div className="">
            <div className="ltn__product-area ltn__product-gutter">
                <div className="container-fluid px-lg-4 px-xl-5">
                    {/* <div className="">
                        <div className="btn-wrapper animated go-top">
                            <button
                                type='button'
                                title=""
                                data-bs-toggle="modal"
                                data-bs-target="#subdivisionMapModal"
                                // className="theme-btn-1 btn btn-effect-1 bg-transparent ltn__secondary-color"
                                className="theme-btn-1 btn btn-effect-1"
                                // onClick={(e) => {
                                //     e.preventDefault();
                                //     setSelectedSubdivion(subdivision);
                                //     setShowSubdivisionModal(true);
                                // }}
                            >
                                Voir sur une carte
                                {" "}
                                <i className="flaticon-right-arrow" />
                            </button>
                        </div>
                    </div> */}
                    <div className="mb-5" style={{height: "400px"}}>
                        <SubdivisionMap
                            subdivision={subdivision}
                            googleEarthUrl={(subdivision.municipality_id === 432) ? 'https://earth.google.com/earth/d/1c7jgyovVzmPeQch0y1DjPKfAx8OVlzxK?usp=sharing' : 'https://earth.google.com/earth/d/1F-CqT6GWX6SPyHxB7CnMN30ItsElEved?usp=sharing'}
                            circleRadius={2000}
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-xl-3 mb-100">
                            <Sidebar subdivision={subdivision} />
                        </div>
                        <div className="col-md-8 col-lg-8 col-xl-9 mb-100">
                            <div className="ltn__shop-options d-flex flex-wrap align-items-center justify-content-between gap-4">
                                <ul className="justify-content-start">
                                    <li className="d-none">
                                        <div className="showing-product-number text-right">
                                            <span>Showing 1–12 of 18 results</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center gap-1">
                                           <label>Trier par:</label>
                                            <div className="short-by text-center">
                                                <select className="nice-select">
                                                {
                                                    sortingElements.map((element, index) => {
                                                        return (
                                                            <option key={index} value={element.value}>{element.wording}</option>
                                                        );
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <div className="d-flex align-items-center gap-1">
                                            <label>Afficher par page:</label>
                                            <div className="show-per-page text-center">
                                                <select className="nice-select">
                                                {
                                                    itemsPerPage.map((it, index) => {
                                                        return (
                                                            <option key={index} value={it}>{it}</option>
                                                        );
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                    </li> */}
                                </ul>
                                <ul className="justify-content-start">
                                    <li>
                                        <div className="ltn__grid-list-tab-menu ">
                                            <div className="nav">
                                                <a
                                                    className="active show"
                                                    data-bs-toggle="tab"
                                                    href="#liton_product_grid"
                                                >
                                                    <i className="fas fa-th-large" />
                                                </a>
                                                <a
                                                    data-bs-toggle="tab"
                                                    href="#liton_product_list"
                                                >
                                                    <i className="fas fa-list" />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade active show"
                                    id="liton_product_grid"
                                >
                                    <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* Search Widget */}
                                                <div className="ltn__search-widget mb-30">
                                                    <form action="#">
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Rerchercher..."
                                                            defaultValue=""
                                                            onInput={(e) => setSearchText(e.currentTarget.value)}
                                                        />
                                                        <button type="submit">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                {/* Search Widget */}
                                            </div>
                                        {
                                            !pageData.length
                                            ?
                                            <NoPlot fromSearch={fromSearch} />
                                            :
                                            pageData.map((land, index) => {
                                                return (
                                                    // {/* ltn__product-item */}
                                                    <div key={index} className="col-12 col-sm-6 col-xxl-4">
                                                    {/* <div className="col-12" key={index}> */}
                                                        <div className="ltn__product-item ltn__product-item-4 text-center---">
                                                            <div className="product-img go-top">
                                                                <Link
                                                                    to={`/terrains/lotissements/parcelles/${land.id}`}
                                                                    onClick={() => setShowLoaderSpinner(true)}
                                                                >
                                                                    <img
                                                                        // src={publicUrl + "assets/img/product-3/1.jpg"}
                                                                        src={`${publicUrl}assets/img/lands/image-terrain-${land.real_estate.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                                                                        // src={land.real_estate.images[0].data}
                                                                        alt="Illustration"
                                                                    />
                                                                </Link>
                                                                <div className="product-badge">
                                                                    <ul>
                                                                        <li className={`sale-badge ${getBadgeColorByStatus(land.real_estate)}`}>{getStatus(land.real_estate)}</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="product-img-location-gallery px-3">
                                                                    <div 
                                                                        className="product-img-location d-flex align-items-center"
                                                                        title={`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                                                                    >
                                                                        <p
                                                                            className="m-0 text-white text-truncate cursor-default"
                                                                            title={`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                                                                        >
                                                                        <small>
                                                                            <i className="flaticon-pin" />{" "}
                                                                            {`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                                                                        </small>
                                                                        </p>
                                                                    </div>
                                                                {
                                                                    land.real_estate.belongs_to_alphaimmo &&
                                                                    <div className="real-estate-agent">
                                                                        <div className="agent-img bg-white rounded-circle p-1 m-0">
                                                                            <a href="/alfaimmo">
                                                                                <img
                                                                                    src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                                    alt="Mascotte ALFAIMMO"
                                                                                    width={32}
                                                                                    title="ALFAIMMO"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="product-info">
                                                                <div className="product-price fs-6">
                                                                    <small>
                                                                        <span>{formatNumber(land.real_estate.price)} <label>FCFA</label></span>
                                                                    {
                                                                        land.real_estate.monthly_price &&
                                                                        <span>{" - "}{formatNumber(land.real_estate.monthly_price)} <label>FCFA</label><label>/Mois</label></span>
                                                                    }
                                                                    </small>
                                                                </div>
                                                                <h3 className="product-title go-top fs-6">
                                                                    <Link to={`/terrains/lotissements/parcelles/${land.id}`}>{`Terrain nu - lot n° ${land.number}`}</Link>
                                                                </h3>
                                                                <div className="product-description">
                                                                    <p className="clamped-text clamped-text-1">{land.real_estate.description}</p>
                                                                </div>
                                                                <ul className="ltn__list-item-2 ltn__list-item-2-before personalized row">
                                                                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                        <span>Supercifie:</span>
                                                                        <span>{parseInt(land.real_estate.superficies)} m<sup>2</sup></span>
                                                                    </li>
                                                                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                        <span>Position:</span>{" "}
                                                                        <span>{land.position === 'CORNER' ? "Angle" : "Simple"}</span>
                                                                    </li>
                                                                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                        <span>Catégorie:</span>{" "}
                                                                        <span>{land.category === 'TF' ? "Titre foncier" : "Bail"}</span>
                                                                    </li>
                                                                    <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                        <span>Viabilisé:</span>
                                                                        <span>{land.serviced === 'YES' ? 'Oui' : 'Non'}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    // {/* ltn__product-item */}
                                                );
                                            })
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="liton_product_list"
                                >
                                    <div className="ltn__product-tab-content-inner ltn__product-list-view">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* Search Widget */}
                                                <div className="ltn__search-widget mb-30">
                                                    <form action="#">
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Rerchercher..."
                                                            defaultValue=""
                                                            onInput={(e) => setSearchText(e.currentTarget.value)}
                                                        />
                                                        <button type="submit">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                {/* Search Widget */}
                                            </div>
                                            {
                                                !pageData.length
                                                ?
                                                <NoPlot fromSearch={fromSearch} />
                                                :
                                                pageData.map((land, index) => {
                                                    return (
                                                        // {/* ltn__product-item */}
                                                        <div key={index} className="col-lg-12">
                                                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                                                <div className="product-img go-top">
                                                                    <Link
                                                                        to={`/terrains/lotissements/parcelles/${land.id}`}
                                                                        onClick={() => setShowLoaderSpinner(true)}
                                                                    >
                                                                        <img
                                                                            // src={publicUrl + "assets/img/product-3/1.jpg"}
                                                                            src={`${publicUrl}assets/img/lands/image-terrain-${land.real_estate.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                                                                            // src={land.real_estate.images[0].data}
                                                                            alt="Illustration"
                                                                        />
                                                                    </Link>
                                                                    <div className="product-badge">
                                                                        <ul>
                                                                            <li className={`sale-badge ${getBadgeColorByStatus(land.real_estate)}`}>{getStatus(land.real_estate)}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="product-img-location-gallery px-3">
                                                                        <div
                                                                            className="product-img-location d-flex align-items-center"
                                                                            title={`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                                                                        >
                                                                            <p
                                                                                className="m-0 text-white text-truncate cursor-default"
                                                                                title={`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                                                                            >
                                                                            <small>
                                                                                <i className="flaticon-pin" />{" "}
                                                                                {`${land.real_estate.municipality.name}, ${land.real_estate.street}`}
                                                                            </small>
                                                                            </p>
                                                                        </div>
                                                                    {
                                                                        land.real_estate.belongs_to_alphaimmo &&
                                                                        <div className="real-estate-agent">
                                                                            <div className="agent-img bg-white rounded-circle p-1 m-0">
                                                                                <a href="/alfaimmo">
                                                                                    <img
                                                                                        src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                                        alt="Mascotte ALFAIMMO"
                                                                                        width={32}
                                                                                        title="ALFAIMMO"
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-price fs-6">
                                                                        <small>
                                                                            <span>{formatNumber(land.real_estate.price)} <label>FCFA</label></span>
                                                                        {
                                                                            land.real_estate.monthly_price &&
                                                                            <span>{" - "}{formatNumber(land.real_estate.monthly_price)} <label>FCFA</label><label>/Mois</label></span>
                                                                        }
                                                                        </small>
                                                                    </div>
                                                                    <h3 className="product-title go-top fs-6">
                                                                        <Link to={`/terrains/lotissements/parcelles/${land.id}`}>{`Terrain nu - lot n° ${land.number}`}</Link>
                                                                    </h3>
                                                                    <div className="product-description">
                                                                        <p className="clamped-text clamped-text-1">{land.real_estate.description}</p>
                                                                    </div>
                                                                    <ul className="ltn__list-item-2 ltn__list-item-2-before personalized row">
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Supercifie:</span>
                                                                            <span>{parseInt(land.real_estate.superficies)} m<sup>2</sup></span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Position:</span>{" "}
                                                                            <span>{land.position === 'CORNER' ? "Angle" : "Simple"}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Catégorie:</span>{" "}
                                                                            <span>{land.category === 'TF' ? "Titre foncier" : "Bail"}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1 col-6 m-0 py-1">
                                                                            <span>Viabilisé:</span>
                                                                            <span>{land.serviced === 'YES' ? 'Oui' : 'Non'}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        // {/* ltn__product-item */}
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__pagination-area text-center">
                                <div className="ltn__pagination">
                                    {/* begin::pagination */}
                                    <div className="pagination-box-wrapper mt-5">
                                        <Pagination
                                            id='landsPagination'
                                            pageCount={pageCount}
                                            currentPageNumber={curentPageNumber}
                                            setCurrentPageNumber={setCurrentPageNumber}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    </div>
                                    {/* end::pagination */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            {/* <div
                className="modal fade"
                id="subdivisionMapModal"
                tabIndex={-1}
            >
                <div
                    className="modal-dialog modal-lg"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{
                                    top: '-16px',
                                    right: '-16px'
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="" style={{height: "400px"}}>
                                <SubdivisionMap subdivision={subdivision} />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/*  */}
        </div>
    );
}

export default Content;

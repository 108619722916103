import { createContext } from "react";

/**
 * Create the data context
*/
const DataContext = createContext({
    apartments: [],
    apartmentPriceRanges: null,
    houses: [],
    housePriceRanges: null,
    lands: [],
    landPriceRanges: null,
    realEstates: [],
    realEstatePriceRanges: null,
    subdivisions: [],

    municipalities: [],
    // departments: [],
    regions: [],

    fetchData: () => {/* */},

    fetchApartments: () => {/* */},
    fetchApartmentPriceRanges: () => {/* */},
    fetchHouses: () => {/* */},
    fetchHousePriceRanges: () => {/* */},
    fetchLands: () => {/* */},
    fetchLandPriceRanges: () => {/* */},
    fetchRealEstates: () => {/* */},
    fetchRealEstatePriceRanges: () => {/* */},
    fetchSubdivisions: () => {/* */},

    fetchMunicipalities:() => {/* */},
    // fetchDepartments:() => {/* */},
    fetchRegions:() => {/* */},

    setApartments: () => {/* */},
    setApartmentPriceRanges: () => {/* */},
    setHouses: () => {/* */},
    setHousePriceRanges: () => {/* */},
    setLands: () => {/* */},
    setLandPriceRanges: () => {/* */},
    setRealEstates: () => {/* */},
    setRealEstatePriceRanges: () => {/* */},
    setSubdivisions: () => {/* */},

    setMunicipalities: () => {/* */},
    // setDepartments: () => {/* */},
    setRegions: () => {/* */},
});

export default DataContext;

/**
 * 
 * @param {*} realEstate 
 * @returns 
 */
export const getStatus = (realEstate) => {
    switch (realEstate.status) {
        case 'RENT':
            return 'À louer';
        case 'SALE':
            return 'À vendre';
        case 'RESERVED':
            return 'Réservé';
        case 'RENTED':
            return 'Loué';
        case 'SOLD':
            return 'Vendu';
        default:
            break;
    }
};

/**
 * 
 * @param {*} realEstate 
 * @returns 
 */
export const getBadgeColorByStatus = (realEstate) => {
    switch (realEstate.status) {
        case 'RENT':
        case 'SALE':
            return 'bg-green';
        case 'RESERVED':
            return '';
        case 'RENTED':
            return '';
        case 'SOLD':
            return '';
        default:
            break;
    }
};

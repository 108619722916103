import React from 'react';

import Navbar2 from '../../../components/global-components/Navbar2';
import PageHeader from '../../../components/global-components/PageHeader';
import Content from '../../../components/real-estate-components/land-components/subdivisions/Content';
import Newsletter from '../../../components/global-components/Newsletter';
import Footer from '../../../components/global-components/Footer';

const Subdivisions = () => {

    return (
        <div>
            <Navbar2 />
            <PageHeader
                headertitle={'Lotissements'}
                breadcrumbs={['terrains', 'lotissements']}
            />
            <Content />
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Subdivisions;

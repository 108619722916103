import React from "react";
import ReactDOM from "react-dom/client";

import DataProvider from "./data/providers/DataProvider";
import LoaderSpinnerProvider from "./components/global-components/providers/LoaderSpinnerProvider";
import ResearchDataProvider from "./components/real-estate-components/providers/ResearchDataProvider";

// import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("quarter"));
root.render(
    <React.StrictMode>
        <LoaderSpinnerProvider>
            <DataProvider>
                <ResearchDataProvider>
                    <App />
                </ResearchDataProvider>
            </DataProvider>
        </LoaderSpinnerProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
